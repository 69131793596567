import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import Avatar from "@mui/material/Avatar";
import Fab from "@mui/material/Fab";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import { Autocomplete } from "@react-google-maps/api";
import useMediaQuery from "@mui/material/useMediaQuery";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import { MuiTelInput } from "mui-tel-input";
import { acceptedPhoneCountries } from "../../../config/constants";
import FormClientInfo from "../forms/FormClientInfo";
import FormPetsInfo from "../forms/FormPetsInfo";

const ClientForm = ({
  state,
  setFiles,
  setState,
  fileInputRef,
  initializeErrors,
  tab,
  setTab,
}) => {
  const getTabContent = (activeTab) => {
    switch (activeTab) {
      case 1:
        return (
          <FormClientInfo
            state={state}
            setState={setState}
            initializeErrors={initializeErrors}
          />
        );
      case 2:
        return (
          <FormPetsInfo
            state={state}
            setState={setState}
            initializeErrors={initializeErrors}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  return (
    <Grid container spacing={2} sx={{ px: 5 }}>
      {/* start: Image Section */}
      <Grid item xs={12} md={3} lg={3}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="flex-end"
          spacing={0}
        >
          <Avatar
            src={state.client?.img64?.imgageBase64 ?? ""}
            sx={{
              width: 160,
              height: 160,
            }}
          />
          <Fab
            sx={{
              width: 40,
              height: 40,
              ml: -6,
            }}
            aria-label="edit"
            color="primaryTk"
            onClick={() => fileInputRef.current.click()}
          >
            <EditIcon color="primaryTk.contrastText" />
          </Fab>
          <input
            type="file"
            className="d-none"
            onChange={(e) => setFiles(e.target.files)}
            ref={fileInputRef}
          />
        </Stack>
        {state.errorImage && (
          <div className="col-lg-12">
            <Alert data-cy="errorAlert" severity="error" sx={{ mt: 3 }}>
              {state.messageImage}
            </Alert>
          </div>
        )}
      </Grid>
      {/* end: Image Section */}
      <Grid item xs={12} md={9} lg={9}>
        {/* start: Tabs Content */}
        {getTabContent(tab)}
        {/* end: Tabs Content */}
      </Grid>
    </Grid>
  );
};

ClientForm.propTypes = {
  state: PropTypes.object.isRequired,
  fileInputRef: PropTypes.object.isRequired,
  address11: PropTypes.object.isRequired,
  address12: PropTypes.object.isRequired,
  address13: PropTypes.object.isRequired,
  setFiles: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
  initializeErrors: PropTypes.func.isRequired,
  onPlaceChanged: PropTypes.func.isRequired,
  isLoaded: PropTypes.any.isRequired,
  onLoad1: PropTypes.any.isRequired,
  onLoad2: PropTypes.any.isRequired,
  onLoad3: PropTypes.any.isRequired,
};

export default ClientForm;

export const ItemEdit = ({
  types,
  label,
  item,
  onRemove,
  index,
  onChange,
  handlePhoneValueChange,
  stateItem,
  errors,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  return (
    <Grid container alignItems="center">
      <Grid item xs={10} lg={12}>
        <Stack
          direction={{ xs: "column", lg: "row" }}
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          sx={{ width: 1 }}
        >
          <TextField
            select
            label={"select " + label + " type"}
            required
            value={item.type}
            onChange={(event) => {
              onChange(event, index, "type");
            }}
            size="small"
            sx={{ width: { xs: 1, lg: 0.25 } }}
            className="item-subtitle"
            error={errors[stateItem][index].type !== ""}
            helperText={errors[stateItem][index].type ?? "Input Req."}
          >
            {types.map((option) => (
              <MenuItem
                className="item-subtitle"
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {label === "phone" && (
            <MuiTelInput
              size="small"
              required
              value={item.value}
              sx={{ width: { xs: 1, lg: 0.3 } }}
              label={label}
              className="item-subtitle"
              error={errors[stateItem][index].value !== ""}
              helperText={errors[stateItem][index].value ?? "Input Req."}
              onChange={(newPhone, info) =>
                handlePhoneValueChange(newPhone, info, index, "value")
              }
              defaultCountry="US"
              forceCallingCode
              onlyCountries={acceptedPhoneCountries}
            />
          )}
          {label !== "phone" && (
            <TextField
              size="small"
              required
              value={item.value}
              onChange={(event) => {
                onChange(event, index, "value");
              }}
              sx={{ width: { xs: 1, lg: 0.3 } }}
              label={label}
              className="item-subtitle"
              error={errors[stateItem][index].value !== ""}
              helperText={errors[stateItem][index].value ?? "Input Req."}
            />
          )}
          {!matches && (
            <IconButton
              aria-label="delete"
              color="error"
              onClick={() => onRemove(index)}
              sx={{ visibility: { xs: "hidden", lg: "visible" } }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Stack>
      </Grid>
      {matches && (
        <Grid item xs={2} lg={12}>
          <Stack
            direction={{ xs: "column", lg: "row" }}
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ width: 1 }}
          >
            <IconButton
              aria-label="delete"
              color="error"
              onClick={() => onRemove(index)}
              sx={{ visibility: { xs: "visible", lg: "hidden" } }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export const AddressItem = ({
  item,
  index,
  stateItem,
  errors,
  onChange,
  isLoaded,
  onLoad,
  onPlaceChanged,
  address1Ref,
  repeatValue,
  sameAsBusiness,
  allowEditField,
}) => {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={1}
      sx={{ width: 1 }}
    >
      <Stack
        direction={{ xs: "column", lg: "row" }}
        justifyContent="flex-start"
        alignItems={{ xs: "flex-start", lg: "center" }}
        spacing={2}
      >
        <Typography
          variant="body1"
          className="item-subtitle"
          sx={{ fontWeight: "bold" }}
        >
          {item.type} Address:
        </Typography>
        {item.type !== "business" && (
          <FormControlLabel
            control={
              <Checkbox
                checked={repeatValue}
                onChange={() => sameAsBusiness(index)}
                size="small"
              />
            }
            label="Use same as Business "
          />
        )}
      </Stack>
      <Stack
        direction={{ xs: "column", lg: "row" }}
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ width: 1 }}
      >
        <Box sx={{ width: { xs: 1, lg: 0.5 } }}>
          {isLoaded ? (
            <Autocomplete
              onLoad={onLoad}
              onPlaceChanged={() => onPlaceChanged(index)}
              restrictions={{ country: ["us"] }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                disabled={repeatValue}
                label="Address Line 1"
                className="item-subtitle"
                InputLabelProps={{ shrink: true }}
                fullWidth
                inputProps={{ ref: address1Ref }}
                error={errors[stateItem][index].address1 !== ""}
                helperText={errors[stateItem][index].address1 ?? "Input Req."}
              />
            </Autocomplete>
          ) : (
            <CircularProgress size="1.5rem" />
          )}
        </Box>
        <TextField
          size="small"
          value={item.address2}
          onChange={(event) => {
            onChange(event, index, "address2");
          }}
          disabled={repeatValue}
          sx={{ width: { xs: 1, lg: 0.5 } }}
          label="Address Line 2"
          className="item-subtitle"
          InputLabelProps={{ shrink: true }}
          error={errors[stateItem][index].address2 !== ""}
          helperText={errors[stateItem][index].address2 ?? "Input Req."}
        />
      </Stack>
      <Stack
        direction={{ xs: "column", lg: "row" }}
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ width: 1 }}
      >
        <TextField
          size="small"
          required
          value={item.city}
          disabled={!allowEditField.city || repeatValue}
          onChange={(event) => {
            onChange(event, index, "city");
          }}
          sx={{ width: { xs: 1, lg: 0.34 } }}
          label="City"
          className="item-subtitle"
          InputLabelProps={{ shrink: true }}
          error={errors[stateItem][index].city !== ""}
          helperText={errors[stateItem][index].city ?? "Input Req."}
        />
        <TextField
          size="small"
          required
          value={item.state}
          disabled={!allowEditField.state || repeatValue}
          onChange={(event) => {
            onChange(event, index, "state");
          }}
          sx={{ width: { xs: 1, lg: 0.33 } }}
          label="State"
          className="item-subtitle"
          InputLabelProps={{ shrink: true }}
          error={errors[stateItem][index].state !== ""}
          helperText={errors[stateItem][index].state ?? "Input Req."}
        />
        <TextField
          size="small"
          required
          value={item.zip}
          onChange={(event) => {
            onChange(event, index, "zip");
          }}
          disabled={!allowEditField.zip || repeatValue}
          sx={{ width: { xs: 1, lg: 0.33 } }}
          label="Zip Code"
          className="item-subtitle"
          InputLabelProps={{ shrink: true }}
          error={errors[stateItem][index].zip !== ""}
          helperText={errors[stateItem][index].zip ?? "Input Req."}
        />
      </Stack>
    </Stack>
  );
};
