import React from "react";
import Typography from "@mui/material/Typography";
import { Stack, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";

const FormPetsInfo = ({ state, setState, initializeErrors }) => {

  const handleGeneralChange = (event, index) => {
    const value = event.target.value;
    const petslist = [...state.petsEdit];
    petslist[index].name = value;
    setState((state) => ({
      ...state,
      petsEdit: petslist,
    }));
  };

  return (
    <Paper elevation={4} sx={{ mt: 3, px: 3, py: 2 }}>
      <Typography
        variant="h5"
        component="h3"
        sx={{ fontWeight: "regular", mb: 4 }}
      >
        Edit pets information
      </Typography>
      {state.petsEdit.length > 0 && (
        <Grid container spacing={5} sx={{ px: 5, mb: 3 }}>
          {state.petsEdit.map((pet, index) => {
            return (
              <Grid item xs={12} md={4} lg={4} key={pet.petId}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Avatar
                    src={pet.img64?.imgageBase64 ?? ""}
                    sx={{
                      width: 120,
                      height: 120,
                    }}
                  />
                  <TextField
                    size="small"
                    required
                    value={pet.name}
                    onChange={(event) => {
                      handleGeneralChange(event, index);
                    }}
                    sx={{ width: { xs: 1, lg: 0.5 } }}
                    label="Pet Name"
                    className="item-subtitle"
                    error={state.errors.pets[index] !== ""}
                    helperText={state.errors.pets[index] ?? "Input Req."}
                  />
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Paper>
  );
};

export default FormPetsInfo;
