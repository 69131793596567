import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function PetCardIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <g id="Icon">
        <path d="M8.86,14.16a1.53,1.53,0,0,1-.7-.2,2.35,2.35,0,0,1,0,1.15L8.24,15a5.29,5.29,0,0,1,.45-.53,1.51,1.51,0,0,1,1-.46h0a1.6,1.6,0,0,1,.44,0,1.91,1.91,0,0,1,1.07.88l0,.07c.13.17.25.35.37.52a2.48,2.48,0,0,1,0-.73,2.29,2.29,0,0,1,.2-.69,1.88,1.88,0,0,1-.74.19.55.55,0,0,1-.24,0,1.32,1.32,0,0,1-.93-.9c0,.06-.05.13-.08.19a1.11,1.11,0,0,1-.52.49A1.06,1.06,0,0,1,8.86,14.16Z"></path>
        <path d="M11,13.56a.92.92,0,0,0,.82-.43,2.76,2.76,0,0,0-.12-1.58.53.53,0,0,0-.57-.35c-.24,0-.52.35-.61,1.24S10.84,13.5,11,13.56Z"></path>
        <path d="M9,13.38a.42.42,0,0,0,.18-.19,2.26,2.26,0,0,0,.15-1.65.83.83,0,0,0,0-.12h0c-.05-.08-.13-.16-.41-.22h0a.34.34,0,0,0-.18.08,2.1,2.1,0,0,0-.59,1.39.65.65,0,0,0,.27.57A.74.74,0,0,0,9,13.38Z"></path>
        <path d="M6.91,13.5h0l-.18,0h0c-.15,0-.27.16-.31.22A1.5,1.5,0,0,0,6.25,15a.5.5,0,0,0,.37.37.68.68,0,0,0,.51-.05A.81.81,0,0,0,7.42,15a1.41,1.41,0,0,0,0-.89A.91.91,0,0,0,6.91,13.5Z"></path>
        <path d="M24.57,7.28H5.42A1.44,1.44,0,0,0,4,8.71V21.29a1.44,1.44,0,0,0,1.44,1.43H24.57A1.45,1.45,0,0,0,26,21.29V8.71A1.4,1.4,0,0,0,24.57,7.28ZM14.21,15.89a1.14,1.14,0,0,1-.73.5,3.1,3.1,0,0,1-.69.1,1.35,1.35,0,0,1-.55-.12l.21.3a2.27,2.27,0,0,1,.5,1,1.83,1.83,0,0,1-.19,1.19,1.4,1.4,0,0,1-.95.66H11.6a2.29,2.29,0,0,1-.77-.17,2.35,2.35,0,0,1-.4-.16A2.36,2.36,0,0,0,9.59,19a4.79,4.79,0,0,0-1,.14l-.15,0a1.41,1.41,0,0,1-1.17,0,1.66,1.66,0,0,1-.74-1.52,2,2,0,0,1,.54-1.18c.07-.09.15-.17.23-.26a2.62,2.62,0,0,0,.22-.25,1.25,1.25,0,0,1-.68.19A1.59,1.59,0,0,1,6.4,16a1.26,1.26,0,0,1-.86-.86,2.23,2.23,0,0,1,.24-1.9,1.13,1.13,0,0,1,1-.57,1.29,1.29,0,0,1,.45.09l.19.1a2,2,0,0,1,0-.25,2.83,2.83,0,0,1,.88-2A.94.94,0,0,1,9,10.47a1.29,1.29,0,0,1,.91.58,2,2,0,0,1,.11.26,1.26,1.26,0,0,1,1-.86,1.29,1.29,0,0,1,1.27.73,3.42,3.42,0,0,1,.21,2.06,1.29,1.29,0,0,1,.7-.18,1.1,1.1,0,0,1,.47.13,1.24,1.24,0,0,1,.6,1,5.26,5.26,0,0,1,0,.91A1.69,1.69,0,0,1,14.21,15.89Zm8.63,1.7H15.47a.47.47,0,0,1-.47-.46.48.48,0,0,1,.47-.47h7.37a.48.48,0,0,1,.47.47A.47.47,0,0,1,22.84,17.59Zm0-2.75H15.47a.47.47,0,0,1-.47-.46.48.48,0,0,1,.47-.47h7.37a.51.51,0,0,1,.47.47A.47.47,0,0,1,22.84,14.84Z"></path>
        <path d="M13.4,13.85a.33.33,0,0,0-.14,0h0a.72.72,0,0,0-.45.21,1.48,1.48,0,0,0-.41.9c-.07.51.08.73.12.76a1.2,1.2,0,0,0,.79,0,.46.46,0,0,0,.28-.15,1.05,1.05,0,0,0,.08-.49,5,5,0,0,0,0-.79A.49.49,0,0,0,13.4,13.85Z"></path>
        <path d="M11.84,17.11l-.47-.68c-.23-.32-.45-.64-.68-.95l-.06-.08c-.23-.32-.39-.52-.65-.59a.78.78,0,0,0-.22,0,.75.75,0,0,0-.54.24,4,4,0,0,0-.39.47c-.25.31-.59.75-1,1.18l-.21.23a1.17,1.17,0,0,0-.36.73.93.93,0,0,0,.38.84c.12.08.36,0,.58-.05l.17,0a5.74,5.74,0,0,1,1.19-.16,2.77,2.77,0,0,1,1.14.31l.26.11a1.65,1.65,0,0,0,.69.15.66.66,0,0,0,.44-.3,1.19,1.19,0,0,0,.08-.71A1.61,1.61,0,0,0,11.84,17.11Z"></path>
      </g>
      </svg>
    </SvgIcon>
  );
}

export default PetCardIcon;
