import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Stack, Container, Snackbar, Alert } from "@mui/material";
import AddressSection from "./sections/AddressSection";
import PhoneSection from "./sections/PhoneSection";
import EmailSection from "./sections/EmailSection";
import SocialSection from "./sections/SocialSection";
import HeaderSection from "./sections/HeaderSection";
import Skeleton from "@mui/material/Skeleton";
import NotFound from "../page/NotFound";
import "./businessCard.css";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import Link from "@mui/material/Link";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { baseUrl } from "../../config/constants";

const BusinessCard = ({ variant }) => {
  let theme = createTheme({
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      body1: {
        fontSize: "1.1rem",
        fontWeight: "normal",
      },
    },
  });
  theme = responsiveFontSizes(theme);

  const [state, setState] = useState({
    loading: true,
    status: "loading",
    message: "",
    client: {
      firstName: "",
      lastName: "",
      company: "",
      address1: "",
      address2: "",
      state: "",
      city: "",
      zip: "",
      emailList: [],
      phoneList: [],
      website: "",
      mediaList: [],
      img64: "",
    },
    pet: {
      name: "",
      img64: "",
    },
    snackMessage: {
      display: false,
      message: "",
      type: "info",
    },
  });
  const params = useParams();

  const fetchVcf = async () => {
    const requestConfig = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };
    const clientId = params.id;
    try {
      const vcfResponse = await fetch(
        `${baseUrl}/clients/${clientId}/vcf`,
        requestConfig
      );
      if (vcfResponse.ok) {
        const vcfData = await vcfResponse.blob();
        const url = await URL.createObjectURL(vcfData);
        const link = document.createElement("a");
        link.href = url;
        // link.setAttribute("download", fileList[index].fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error(error);
      setState((state) => ({
        ...state,
        loading: false,
        status: "error",
        message: error.message ?? "",
      }));
    }
  };

  useEffect(() => {
    const snackMessage = {
      display: false,
      message: "",
      type: "info",
    };
    const clientId = params.id;
    const requestConfig = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    const setErrorOnState = (status, message) => {
      setState((state) => ({
        ...state,
        loading: false,
        status: status,
        message: message,
      }));
    };

    const fetchImage = async (img_name, type) => {
      let imgageBase64 = null;
      try {
        const imgResponse = await fetch(
          `${baseUrl}/${type}/images/${img_name}`,
          requestConfig
        );
        if (imgResponse.ok) {
          const imgData = await imgResponse.blob();
          const objectURL = await URL.createObjectURL(imgData);
          imgageBase64 = objectURL;
        }
      } catch (error) {
        console.error(error);
        setState((state) => ({
          ...state,
          loading: false,
          status: "error",
          message: error.message ?? "",
        }));
      }
      return imgageBase64;
    };

    const fetchClientData = async (id) => {
      try {
        const response = await fetch(
          `${baseUrl}/clients/${id}/vcard`,
          requestConfig
        );
        const responseData = await response.json();

        if (response.ok) {
          const client = responseData;

          if (client.image) {
            client.img64 = await fetchImage(client.image, "clients");
          }
          if (responseData.notification) {
            snackMessage.display = true;
            snackMessage.type = responseData.notification.type;
            snackMessage.message = responseData.notification.message;
          }
          setState((state) => ({
            ...state,
            status: "ok",
            client,
            loading: false,
            snackMessage,
          }));
        } else {
          setErrorOnState(
            "error",
            responseData.message ?? "Error fetching client"
          );
        }
      } catch (error) {
        console.error(error);
        setErrorOnState("error", error.message ?? "");
      }
    };

    const fetchPetData = async (id) => {
      try {
        const response = await fetch(
          `${baseUrl}/pets/${id}/vcard`,
          requestConfig
        );
        const responseData = await response.json();
        if (response.ok) {
          const pet = {
            name: responseData.name,
            image: responseData.image,
          };
          const client = responseData.client;

          if (pet.image) {
            pet.img64 = await fetchImage(pet.image, "pets");
          }
          if (client.image) {
            client.img64 = await fetchImage(client.image, "clients");
          }
          if (responseData.notification) {
            snackMessage.display = true;
            snackMessage.type = responseData.notification.type;
            snackMessage.message = responseData.notification.message;
          }
          setState((state) => ({
            ...state,
            status: "ok",
            pet,
            client,
            loading: false,
            snackMessage,
          }));
        } else {
          setErrorOnState("error", responseData.message ?? "");
        }
      } catch (error) {
        console.error(error);
        setErrorOnState("error", error.message ?? "");
      }
    };

    if (clientId !== undefined) {
      variant === "business"
        ? fetchClientData(clientId)
        : fetchPetData(clientId);
    } else {
      setErrorOnState("error", "Client not provided");
    }
  }, [params, variant]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      snackMessage: {
        display: false,
        message: "",
        type: "info",
      },
    }));
  };

  return (
    <>
      {state.status !== "error" && (
        <ThemeProvider theme={theme}>
          <div id="bg" />
          <Container maxWidth="xs" sx={{ pb: variant === "business" ? 4 : 8 }}>
            <HeaderSection variant={variant} downloadFunction={fetchVcf} />
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={-8}
              sx={{ pt: 10 }}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="flex-end"
                spacing={0}
              >
                {state.loading && (
                  <>
                    <Skeleton variant="circular">
                      <Avatar
                        className="avatarBusiness"
                        sx={{
                          width: 128,
                          height: 128,
                          border: 4,
                          borderColor:
                            variant === "business" ? "#FFF" : "#D29E0E",
                        }}
                      />
                    </Skeleton>
                  </>
                )}
                {!state.loading && (
                  <>
                    <Avatar
                      className="avatarBusiness"
                      src={
                        variant === "business"
                          ? state.client.img64
                          : state.pet.img64
                      }
                      sx={{
                        width: 128,
                        height: 128,
                        border: 4,
                        borderColor:
                          variant === "business" ? "#FFF" : "#D29E0E",
                      }}
                    />
                  </>
                )}
                {variant !== "business" && (
                  <>
                    {state.loading && (
                      <>
                        <Skeleton
                          variant="circular"
                          sx={{
                            ml: -3,
                          }}
                        >
                          <Avatar
                            className="avatarBusiness"
                            sx={{
                              width: 60,
                              height: 60,
                            }}
                          />
                        </Skeleton>
                      </>
                    )}
                    {!state.loading && (
                      <>
                        <Avatar
                          className="avatarBusiness"
                          src={state.client.img64}
                          sx={{
                            width: 60,
                            height: 60,
                            ml: -3,
                            border: 3,
                            borderColor: "#FFF",
                          }}
                        />
                      </>
                    )}
                  </>
                )}
              </Stack>
              <Card
                variant="outlined"
                sx={{ pt: 7, minWidth: 1 }}
                className="main-card"
              >
                <CardContent>
                  <Box>
                    <Typography
                      variant="h3"
                      align="center"
                      component="h2"
                      sx={{ fontWeight: "bold" }}
                    >
                      {state.status === "loading" ? (
                        <Skeleton />
                      ) : variant === "business" ? (
                        `${state.client.firstName} ${state.client.lastName}`
                      ) : (
                        state.pet.name
                      )}
                    </Typography>
                    <Typography
                      variant="h6"
                      align="center"
                      component="h3"
                      sx={{ fontWeight: "light" }}
                    >
                      {state.status === "loading" ? (
                        <Skeleton />
                      ) : variant === "business" ? (
                        state.client.company || ""
                      ) : (
                        `${state.client.firstName} ${state.client.lastName}`
                      )}
                    </Typography>

                    <Typography
                      variant="body2"
                      align="center"
                      component="h3"
                      sx={{ fontWeight: "light" }}
                    >
                      {state.status === "loading" && <Skeleton />}
                      {state.status !== "loading" &&
                        state.client.website !== "" && (
                          <Link
                            href={state.client.website}
                            underline="none"
                            color="inherit"
                            sx={{ fontFamily: "default" }}
                          >
                            {state.client.website}
                          </Link>
                        )}
                    </Typography>
                  </Box>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={3}
                    sx={{ mt: 3 }}
                  >
                    <AddressSection
                      address1={state.client.address1}
                      address2={state.client.address2}
                      city={state.client.city}
                      state={state.client.state}
                      zip={state.client.zip}
                      loading={state.loading}
                    />
                    <PhoneSection
                      phoneList={state.client.phoneList}
                      loading={state.loading}
                    />
                    <EmailSection
                      emailList={state.client.emailList}
                      loading={state.loading}
                    />
                    {variant === "business" && (
                      <SocialSection
                        mediaList={state.client.mediaList}
                        loading={state.loading}
                      />
                    )}
                  </Stack>
                </CardContent>
              </Card>
            </Stack>
          </Container>
          <Snackbar
            open={state.snackMessage.display}
            autoHideDuration={state.snackMessage.type !== "error" ? 5000 : null}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity={state.snackMessage.type} sx={{ width: "100%" }}>
              {state.snackMessage.message}
            </Alert>
          </Snackbar>
        </ThemeProvider>
      )}
      {state.status === "error" && <NotFound message={state.message} />}
    </>
  );
};

BusinessCard.defaultProps = {
  variant: "",
};

BusinessCard.propTypes = {
  variant: PropTypes.string,
};

export default BusinessCard;
