import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DisplayPersonalInfo from './DisplayPersonalInfo';
import DisplayPetsInfo from './DisplayPetsInfo';
import DisplaySubscriptionInfo from './DisplaySubscriptionInfo';
import CheckoutForm from '../forms/CheckoutForm';

const ClientDisplay = ({
  state,
  setState,
  setFiles,
  onEditClick,
  fileInputRef,
  tab,
  setTab,
  setOpenNewPet,
  setOpenNewBusiness,
  setOpenCancelPet,
  setPetIndexCancel,
  setOpenCancelBusiness,
  refreshSubscriptions,
}) => {
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const toggleCheckout = () => {
    setState((state) => ({
      ...state,
      paying: false,
    }));
  };

  const getTabContent = (activeTab) => {
    switch (activeTab) {
      case 0:
        return (
          <DisplaySubscriptionInfo
            state={state}
            setState={setState}
            setTab={setTab}
            setOpenNewBusiness={setOpenNewBusiness}
            setOpenCancelPet={setOpenCancelPet}
            setPetIndexCancel={setPetIndexCancel}
            setOpenCancelBusiness={setOpenCancelBusiness}
            refreshSubscriptions={refreshSubscriptions}
          />
        );
      case 1:
        return <DisplayPersonalInfo state={state} />;
      case 2:
        return (
          <DisplayPetsInfo
            state={state}
            setState={setState}
            setOpenCancelPet={setOpenCancelPet}
            setPetIndexCancel={setPetIndexCancel}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  };

  return (
    <Grid container spacing={2} sx={{ px: { xs: 3, md: 5 } }}>
      {/* start: Image Section */}
      <Grid item xs={12} md={12} sx={{ mx: 5 }}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems={{ xs: 'center', md: 'flex-end' }}
          spacing={0}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="center"
            alignItems={{ xs: 'center', md: 'flex-end' }}
            spacing={5}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="flex-end"
              spacing={0}
            >
              <Avatar
                src={state.client?.img64?.imgageBase64 ?? ''}
                sx={{
                  width: 160,
                  height: 160,
                }}
              />
              <Fab
                sx={{
                  width: 40,
                  height: 40,
                  ml: -6,
                }}
                aria-label="edit"
                color="primaryTk"
                onClick={() => fileInputRef.current.click()}
              >
                <EditIcon color="primaryTk.contrastText" />
              </Fab>
              <input
                type="file"
                className="d-none"
                onChange={(e) => setFiles(e.target.files)}
                ref={fileInputRef}
              />
            </Stack>
            <Stack
              direction="column"
              justifyContent="flex-end"
              alignItems={{ xs: 'center', md: 'flex-start' }}
              spacing={0}
            >
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                {state.client.firstName} {state.client.lastName}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {state.client.phone}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {state.client.email}
              </Typography>
              <Typography variant="body1">
                {state.client.company} - {state.client.website}
              </Typography>
            </Stack>
          </Stack>
          {!state.editing && tab !== 0 && (
            <Stack direction="row" spacing={1} sx={{ mt: { xs: 3, md: 0 } }}>
              {!state.editing && tab === 2 && (
                <Button
                  color="primaryTk"
                  variant="contained"
                  onClick={() => setOpenNewPet(true)}
                >
                  Add Pet
                </Button>
              )}
              {!state.editing && tab === 1 && !state.hasBusiness && (
                <Button
                  color="primaryTk"
                  variant="contained"
                  onClick={() => setOpenNewBusiness(true)}
                >
                  Add Business
                </Button>
              )}

              <Button
                color="primaryTk"
                variant="contained"
                onClick={onEditClick}
              >
                Edit
              </Button>
            </Stack>
          )}

          {state.paying && (
            <Button
              color="primaryTk"
              variant="contained"
              onClick={toggleCheckout}
              sx={{ mt: { xs: 3, md: 0 } }}
            >
              Back
            </Button>
          )}
        </Stack>
        {state.errorImage && (
          <div className="col-lg-12">
            <Alert data-cy="errorAlert" severity="error" sx={{ mt: 3 }}>
              {state.messageImage}
            </Alert>
          </div>
        )}
      </Grid>
      {/* end: Image Section */}

      <Grid item xs={12} md={3} />
      {state.paying && (
        <Grid item xs={12} md={7} lg={7}>
          <CheckoutForm
            state={state}
            setState={setState}
            item={state.payingItem ?? null}
            subscriptionId={state.subscriptionId}
            isRebuying={state.rebuying ?? false}
          />
        </Grid>
      )}
      {!state.paying && (
        <Grid item xs={12} md={9} lg={9}>
          {/* start: Tabs Container */}
          <Box sx={{ width: 1, bgcolor: 'background.paper' }}>
            <Tabs
              value={tab}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Subscription" />
              {!state.blockAll && <Tab label="Client" />}
              {!state.blockAll && <Tab label="Pets" />}
            </Tabs>
          </Box>
          {/* end: Tabs Container */}

          {/* start: Tabs Content */}
          {getTabContent(tab)}
          {/* end: Tabs Content */}
        </Grid>
      )}
    </Grid>
  );
};

ClientDisplay.propTypes = {
  state: PropTypes.object.isRequired,
  fileInputRef: PropTypes.object.isRequired,
  setFiles: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

export default ClientDisplay;
