import React, { useState, useContext } from 'react';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LoginIcon from '@mui/icons-material/Login';
import Button from '@mui/material/Button';
import logo from '../../../image/logo.png';
import { Box, Stack, AppBar } from '@mui/material';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { AuthContext } from '../../../App';
import BadgeIcon from '@mui/icons-material/Badge';
import { ProfileContext } from '../Profile';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProfileHeaderSection = ({ variant, downloadFunction }) => {
  const authContext = useContext(AuthContext);
  const profileContext = useContext(ProfileContext);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const logout = () => {
    localStorage.removeItem('paymentInfo');
    const contextInitialValue = { ...authContext.initalAuthState };
    authContext.setAuth(contextInitialValue);
    window.location.assign('/login');
  };

  return (
    <AppBar className="header-bg">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ px: 1, py: 1.5 }}
      >
        <Box>
          <Link to="/">
            <img
              src={logo}
              width="82"
              height="35"
              alt="TapKards Logo"
              className=""
            />
          </Link>
        </Box>
        <Box>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            {!authContext.auth.loggedIn && (
              <>
                {variant === 'business' && (
                  <>
                    <Button
                      className="header-button"
                      variant="contained"
                      disableRipple
                      onClick={handleClickOpen}
                      sx={{ minWidth: 40, maxWidth: 40 }}
                    >
                      <QrCode2Icon className="header-icon-color" />
                    </Button>
                    <Button
                      className="header-button"
                      variant="contained"
                      disableRipple
                      sx={{ minWidth: 40, maxWidth: 40 }}
                      onClick={downloadFunction}
                    >
                      <FileDownloadIcon className="header-icon-color" />
                    </Button>
                  </>
                )}

                <Link to="/login">
                  <Button
                    className="header-button"
                    variant="contained"
                    disableRipple
                    sx={{ minWidth: 40, maxWidth: 40 }}
                  >
                    <LoginIcon className="header-icon-color" />
                  </Button>
                </Link>
              </>
            )}

            {authContext.auth.loggedIn && (
              <>
                {profileContext.profileState.subscriptions[0]
                  .hasBusinessCard && (
                  <Link
                    to={'/business/' + authContext.auth.authItem.decodedJwt.sub}
                    className="text-light text-center"
                  >
                    <Button
                      className="header-button"
                      variant="contained"
                      disableRipple
                    >
                      <BadgeIcon className="header-icon-color" />
                      <span className="header-icon-color pl-2">My Card</span>
                    </Button>
                  </Link>
                )}
                <Button
                  className="header-button"
                  variant="contained"
                  disableRipple
                  sx={{ minWidth: 40, maxWidth: 40 }}
                  onClick={logout}
                >
                  <LogoutIcon className="header-icon-color" />
                </Button>
              </>
            )}
          </Stack>
        </Box>
      </Stack>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="dialog for displaying the QR code of the current business card"
      >
        <DialogContent>
          <QRCode value={window.location.href} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
};

ProfileHeaderSection.defaultProps = {
  variant: '',
};

ProfileHeaderSection.propTypes = {
  variant: PropTypes.string,
  downloadFunction: PropTypes.func,
};

export default ProfileHeaderSection;
