import { environments } from './environments';

export const baseUrl = environments[process.env.NODE_ENV].baseUrl;

export const phoneTypes = [
  { value: 'cell', label: 'cell' },
  { value: 'home', label: 'home' },
  { value: 'work', label: 'work' },
];

export const emailTypes = [
  { value: 'home', label: 'home' },
  { value: 'work', label: 'work' },
];

export const addressTypes = [
  { value: 'business', label: 'business' },
  { value: 'shipping', label: 'shipping' },
  { value: 'billing', label: 'billing' },
];

export const libraries = ['places'];

export const acceptedPhoneCountries = ['US'];

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
export const singleNameRegex = /^[a-zA-ZñÑáéíóú\\-\\'. ]+$/;
export const namesRegex = /^[a-zA-Z]+([ '-][a-zA-Z]+)*$/;
export const sessionTime = 15;
export const paymentAttemptTime = 1440;
