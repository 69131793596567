import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import { CheckoutContext } from "./Checkout";
import { Box, Stack } from "@mui/material";
import "../../css/checkout.css";
import PetCardIcon from "../icons/PetCardIcon";
import BusinessCardIcon from "../icons/BusinessCardIcon";
import BusinessPetCardIcon from "../icons/BusinessPetCardIcon";
import Alert from "@mui/material/Alert";

const SubscriptionForm = () => {
  const checkoutContext = useContext(CheckoutContext);

  const addItem = (index) => {
    const productsArray = [...checkoutContext.checkoutState.products];
    productsArray.forEach((element) => {
      element.added = false;
    });
    productsArray[index] = { ...productsArray[index], added: true };

    checkoutContext.setCheckoutState({
      ...checkoutContext.checkoutState,
      products: productsArray,
      snackMessage: {
        display: false,
        message: "",
        type: "info",
      }
    });
  };

  const removeItem = (index) => {
    const productsArray = [...checkoutContext.checkoutState.products];
    productsArray[index] = { ...productsArray[index], added: false };

    checkoutContext.setCheckoutState({
      ...checkoutContext.checkoutState,
      products: productsArray,
    });
  };

  const handleQtyChange = (event, index) => {
    if (isNaN(event.target.value)) {
      return;
    }
    const value = event.target.value < 1 ? 1 : event.target.value;
    const productsArray = [...checkoutContext.checkoutState.products];
    let price = 0;

    if (productsArray[index].businessCard) {
      price += checkoutContext.checkoutState.prices.subscription;
      price += value * checkoutContext.checkoutState.prices.additional;
    } else {
      price += checkoutContext.checkoutState.prices.subscription;
      price += (value - 1) * checkoutContext.checkoutState.prices.additional;
    }
    productsArray[index].petQty = parseInt(value);
    productsArray[index].price = parseInt(price);

    checkoutContext.setCheckoutState({
      ...checkoutContext.checkoutState,
      products: productsArray,
    });
  };

  const addOne = (index) => {
    const productsArray = [...checkoutContext.checkoutState.products];
    const qty = productsArray[index].petQty + 1;
    const event = {
      target: {
        value: qty,
      },
    };
    handleQtyChange(event, index);
  };

  const minusOne = (index) => {
    const productsArray = [...checkoutContext.checkoutState.products];
    if (productsArray[index].petQty < 1) {
      return;
    }
    const qty = productsArray[index].petQty - 1;
    const event = {
      target: {
        value: qty,
      },
    };
    handleQtyChange(event, index);
  };

  const iconsArray = [
    <BusinessCardIcon
      sx={{
        color: "#33428C",
        opacity: 1,
        zIndex: 1,
        width: "2.7rem",
        height: "2.7rem",
      }}
    />,
    <PetCardIcon
      sx={{
        color: "#33428C",
        opacity: 1,
        zIndex: 1,
        width: "2.7rem",
        height: "2.7rem",
      }}
    />,
    <BusinessPetCardIcon
      sx={{
        color: "#33428C",
        opacity: 1,
        zIndex: 1,
        width: "2.7rem",
        height: "2.7rem",
      }}
    />,
  ];

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        sx={{ mt: -4, mb: 5 }}
      >
        {checkoutContext.checkoutState.products.map((product, index) => {
          return (
            <Grid item xs={12} sm={4} textAlign="center" key={product.type}>
              <div className="price-box h-100 ">
                <div className="p-box-inner h-100 d-flex flex-column justify-content-between pb-4">
                  <Box>
                    <div className="price-box-header d-flex justify-content-center">
                      <div className="plan-icons mx-0 my-auto">
                        {iconsArray[index]}
                      </div>
                    </div>

                    <div className="product-title mt-2">
                      <Typography
                        component="h3"
                        variant="subtitle1"
                        sx={{ fontWeight: 600 }}
                      >
                        {product.label}
                      </Typography>
                    </div>

                    <div className="qty-controls">
                      {product.petCard && (
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={0}
                          sx={{ mt: 1 }}
                        >
                          <Button
                            color="secondaryTk"
                            variant="contained"
                            size="small"
                            className="nmbButton"
                            onClick={() => {
                              minusOne(index);
                            }}
                          >
                            -
                          </Button>
                          <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            className="centeredInput"
                            sx={{ width: 0.3 }}
                            disabled={product.added}
                            onChange={(event) => handleQtyChange(event, index)}
                            value={product.petQty}
                            variant="standard"
                            size="small"
                          />
                          <Button
                            color="secondaryTk"
                            variant="contained"
                            size="small"
                            disableRipple
                            className="nmbButton"
                            onClick={() => {
                              addOne(index);
                            }}
                          >
                            +
                          </Button>
                        </Stack>
                      )}
                    </div>
                  </Box>

                  <Box>
                    <div className="price mb-2">
                      <Typography
                        component="h3"
                        variant="h5"
                        sx={{ fontWeight: 600, color: "#33428C" }}
                      >
                        ${product.price}
                      </Typography>
                    </div>
                    <div className="product-cta">
                      {!product.added && (
                        <Button
                          variant="contained"
                          color="primaryTk"
                          onClick={() => addItem(index)}
                          sx={{ borderRadius: "30px" }}
                          startIcon={<AddShoppingCartIcon />}
                        >
                          SELECT
                        </Button>
                      )}
                      {product.added && (
                        <Button
                          variant="contained"
                          color="primaryTk"
                          onClick={() => removeItem(index)}
                          sx={{ borderRadius: "30px" }}
                          startIcon={<ProductionQuantityLimitsIcon />}
                        >
                          REMOVE
                        </Button>
                      )}
                    </div>
                  </Box>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
      {checkoutContext.checkoutState.snackMessage.display && (
        <Alert severity={checkoutContext.checkoutState.snackMessage.type}>
          {checkoutContext.checkoutState.snackMessage.message}
        </Alert>
      )}
    </>
  );
};

export default SubscriptionForm;
