import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  createContext,
} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddressForm from './AddressForm';
import SubscriptionForm from './SubscriptionForm';
import Review from './Review';
import LandingNavBar from '../landing/components/LandingNavbar';
import LandingFooter from '../landing/components/LandingFooter';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useJsApiLoader } from '@react-google-maps/api';
import { libraries, emailRegex, singleNameRegex } from '../../config/constants';
import { termsAndConditions } from '../../config/terms';
import { fetchPrices, landingSubscription } from '../../config/api';
import { AuthContext } from '../../App';
import { CircularProgress, Grid } from '@mui/material';
import { matchIsValidTel } from 'mui-tel-input';
import { removeWhiteSpaces } from '../../utils/utils';

const blankEditAddress = {
  address1: true,
  address2: true,
  zip: false,
  city: false,
  stateAdress: false,
};
const blankAddress = {
  address1: '',
  address2: '',
  zip: '',
  city: '',
  stateAddress: '',
};
const addressError = {
  address1: '',
  address2: '',
  zip: '',
  city: '',
  stateAddress: '',
};
const steps = ['Subscription', 'Basic Info', 'Review'];
const initialErrors = {
  client: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  pets: [],
  payment: {
    cardName: '',
    cardNumber: '',
    expiry: '',
    cvv: '',
  },
  addresses: [addressError, addressError, addressError],
};

export const CheckoutContext = createContext({
  prices: {
    subscription: 0,
    additional: 0,
  },
  products: [
    {
      type: 'businessOnly',
      businessCard: true,
      businessQty: 1,
      petCard: false,
      petQty: 0,
      price: 0,
      label: 'Business Card',
      added: false,
    },
    {
      type: 'petsOnly',
      businessCard: false,
      businessQty: 0,
      petCard: true,
      petQty: 1,
      price: 0,
      label: 'Pet Card',
      added: false,
    },
    {
      type: 'businessPet',
      businessCard: true,
      businessQty: 1,
      petCard: true,
      petQty: 1,
      price: 0,
      label: 'Business + Pet',
      added: false,
    },
  ],
  client: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  pets: [],
  addresses: [
    { ...blankAddress, type: 'business' },
    { ...blankAddress, type: 'shipping' },
    { ...blankAddress, type: 'billing' },
  ],
  payment: {
    cardName: '',
    cardNumber: '',
    expiry: '',
    cvv: '',
  },
  termsCheck: false,
  addressRepeat: [false, true, true],
  allowEditAdresses: [
    { ...blankEditAddress },
    { ...blankEditAddress },
    { ...blankEditAddress },
  ],
  searchedAdresses: [false, false, false],
  errors: initialErrors,
  snackMessage: {
    display: false,
    message: '',
    type: 'info',
  },
});
const defaultTheme = createTheme({
  palette: {
    primaryTk: {
      main: '#33428C',
      light: '#5b67a3',
      dark: '#232e62',
      contrastText: '#fff',
    },
    disabledButton: {
      main: '#5b67a3',
      light: '#5b67a3',
      dark: '#5b67a3',
      contrastText: '#fff',
    },
    secondaryTk: {
      main: '#d9d9d9',
      light: '#e0e0e0',
      dark: '#979797',
      contrastText: '#000',
    },
    white: {
      main: '#fff',
    },
  },
});

const Checkout = () => {
  const authContext = useContext(AuthContext);
  const [state, setState] = useState({
    loading: true,
    status: 'loading',
    message: '',
    landing: {},
  });

  useEffect(() => {
    const configLanding = async () => {
      const config = await fetchPrices();
      if (config.success) {
        authContext.setAuth({
          ...authContext.auth,
          landing: config,
        });
        setState((state) => ({
          ...state,
          status: config.success,
          loading: false,
          landing: config,
        }));
      } else {
        setState((state) => ({
          ...state,
          status: config.success,
          loading: false,
        }));
      }
    };
    if (authContext.auth.landing === undefined) {
      configLanding();
    } else {
      setState((state) => ({
        ...state,
        status: true,
        loading: false,
        landing: authContext.auth.landing,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {state.loading && (
        <ThemeProvider theme={defaultTheme}>
          <LandingNavBar />

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ py: 7, my: 7 }}
          >
            <Grid item xs={12} className="text-center py-5 my-5">
              <CircularProgress size="2.5rem " />
            </Grid>
          </Grid>

          <LandingFooter />
        </ThemeProvider>
      )}
      {!state.loading && <CheckoutContainer />}
    </>
  );
};

const CheckoutContainer = () => {
  const authContext = useContext(AuthContext);
  const [requesting, setRequesting] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [buttonColor, setButtonColor] = useState('disabledButton');
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const initialState = {
    prices: {
      subscription: authContext.auth.landing.subscriptionCost ?? 0,
      additional: authContext.auth.landing.additionalCost ?? 0,
    },
    products: [
      {
        type: 'businessOnly',
        businessCard: true,
        businessQty: 1,
        petCard: false,
        petQty: 0,
        price: authContext.auth.landing.subscriptionCost,
        label: 'Business Card',
        added: false,
      },
      {
        type: 'petsOnly',
        businessCard: false,
        businessQty: 0,
        petCard: true,
        petQty: 1,
        price: authContext.auth.landing.subscriptionCost,
        label: 'Pet Card',
        added: false,
      },
      {
        type: 'businessPet',
        businessCard: true,
        businessQty: 1,
        petCard: true,
        petQty: 1,
        price:
          authContext.auth.landing.subscriptionCost +
          authContext.auth.landing.additionalCost,
        label: 'Business + Pet',
        added: false,
      },
    ],
    client: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    pets: [],
    addresses: [
      { ...blankAddress, type: 'business' },
      { ...blankAddress, type: 'shipping' },
      { ...blankAddress, type: 'billing' },
    ],
    payment: {
      cardName: '',
      cardNumber: '',
      expiry: '',
      cvv: '',
    },
    termsCheck: false,
    addressRepeat: [false, true, true],
    allowEditAdresses: [
      { ...blankEditAddress },
      { ...blankEditAddress },
      { ...blankEditAddress },
    ],
    searchedAdresses: [false, false, false],
    errors: initialErrors,
    snackMessage: {
      display: false,
      message: '',
      type: 'info',
    },
    subscriptionId: '',
  };

  const [checkoutState, setCheckoutState] = useState(initialState);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: authContext.auth.landing.googleApiKey,
    libraries,
  });

  const address11 = useRef(null);
  const address12 = useRef(null);
  const address13 = useRef(null);
  const [map1, setMap1] = React.useState(null);
  const [map2, setMap2] = React.useState(null);
  const [map3, setMap3] = React.useState(null);

  const onLoad1 = React.useCallback(function callback(map) {
    setMap1(map);
  }, []);
  const onLoad2 = React.useCallback(function callback(map) {
    setMap2(map);
  }, []);
  const onLoad3 = React.useCallback(function callback(map) {
    setMap3(map);
  }, []);

  const onPlaceChanged = (index) => {
    const mapObject = index === 0 ? map1 : index === 1 ? map2 : map3;

    if (mapObject.autocomplete !== null && mapObject.getPlace()) {
      const place = mapObject.getPlace();
      if (place.name === '') {
        let address1 = '';
        const clientEditAddresslist = [...checkoutState.addresses];
        clientEditAddresslist[index] = {
          ...clientEditAddresslist[index],
          address1: '',
          address2: '',
          zip: '',
          city: '',
          stateAddress: '',
        };
        const allowEditList = [...checkoutState.allowEditAdresses];
        allowEditList[index] = {
          ...allowEditList[index],
          zip: false,
          city: false,
          stateAddress: false,
        };
        index === 0
          ? (address11.current.value = address1)
          : index === 1
          ? (address12.current.value = address1)
          : (address13.current.value = address1);

        if (index === 0) {
          if (checkoutState.addressRepeat[1]) {
            clientEditAddresslist[1] = {
              ...clientEditAddresslist[1],
              address1: '',
              address2: '',
              zip: '',
              city: '',
              stateAddress: '',
            };
            allowEditList[1] = {
              ...allowEditList[1],
              zip: false,
              city: false,
              stateAddress: false,
            };
            // address12.current.value = address1;
          }
          if (checkoutState.addressRepeat[2]) {
            clientEditAddresslist[2] = {
              ...clientEditAddresslist[2],
              address1: '',
              address2: '',
              zip: '',
              city: '',
              stateAddress: '',
            };
            allowEditList[2] = {
              ...allowEditList[2],
              zip: false,
              city: false,
              stateAddress: false,
            };
            // address13.current.value = address1;
          }
        }

        const searched = [...checkoutState.searchedAdresses];
        searched[index] = false;

        setCheckoutState((checkoutState) => ({
          ...checkoutState,
          addresses: clientEditAddresslist,
          allowEditAdresses: allowEditList,
          searchedAdresses: searched,
        }));
        return;
      }

      let address1 = '';
      let zip = '';
      let city = '';
      let stateAdress = '';

      try {
        for (const component of place.address_components) {
          const componentType = component.types[0];
          switch (componentType) {
            case 'street_number':
              address1 = `${component.long_name} ${address1}`;
              break;

            case 'route':
              address1 += component.long_name;
              break;

            case 'postal_code':
              zip = `${component.long_name}${zip}`;
              break;

            case 'postal_code_suffix':
              zip = `${zip}-${component.long_name}`;
              break;

            case 'locality':
              city = component.long_name;
              break;

            case 'administrative_area_level_1':
              stateAdress = component.short_name;
              break;
            default:
              break;
          }
        }
        const clientEditAddresslist = [...checkoutState.addresses];
        clientEditAddresslist[index] = {
          ...clientEditAddresslist[index],
          address1,
          address2: '',
          zip,
          city,
          stateAddress: stateAdress,
        };
        const allowEditList = [...checkoutState.allowEditAdresses];
        allowEditList[index] = {
          ...allowEditList[index],
          zip: zip === '',
          city: city === '',
          stateAddress: checkoutState === '',
        };
        if (index === 0) {
          if (checkoutState.addressRepeat[1]) {
            clientEditAddresslist[1] = {
              ...clientEditAddresslist[1],
              address1,
              address2: '',
              zip,
              city,
              stateAddress: stateAdress,
            };
            allowEditList[1] = {
              ...allowEditList[1],
              zip: false,
              city: false,
              stateAddress: false,
            };
            // address12.current.value = address1;
          }
          if (checkoutState.addressRepeat[2]) {
            clientEditAddresslist[2] = {
              ...clientEditAddresslist[2],
              address1,
              address2: '',
              zip,
              city,
              stateAddress: stateAdress,
            };
            allowEditList[2] = {
              ...allowEditList[2],
              zip: false,
              city: false,
              stateAddress: false,
            };
            // address13.current.value = address1;
          }
        }

        index === 0
          ? (address11.current.value = address1)
          : index === 1
          ? (address12.current.value = address1)
          : (address13.current.value = address1);

        const searched = [...checkoutState.searchedAdresses];
        searched[index] = true;

        setCheckoutState((checkoutState) => ({
          ...checkoutState,
          addresses: clientEditAddresslist,
          allowEditAdresses: allowEditList,
          searchedAdresses: searched,
        }));
      } catch (error) {
        console.error(error);

        const clientEditAddresslist = [...checkoutState.addresses];
        clientEditAddresslist[index] = {
          ...clientEditAddresslist[index],
          address1: place.name,
          address2: '',
          zip: '',
          city: '',
          stateAddress: '',
        };
        const allowEditList = [...checkoutState.allowEditAdresses];
        allowEditList[index] = {
          ...allowEditList[index],
          zip: true,
          city: true,
          stateAddress: true,
        };
        index === 0
          ? (address11.current.value = place.name)
          : index === 1
          ? (address12.current.value = place.name)
          : (address13.current.value = place.name);

        if (index === 0) {
          if (checkoutState.addressRepeat[1]) {
            clientEditAddresslist[1] = {
              ...clientEditAddresslist[1],
              address1: place.name,
              address2: '',
              zip: '',
              city: '',
              stateAddress: '',
            };
            allowEditList[1] = {
              ...allowEditList[1],
              zip: true,
              city: true,
              stateAddress: true,
            };
            // address12.current.value = address1;
          }
          if (checkoutState.addressRepeat[2]) {
            clientEditAddresslist[2] = {
              ...clientEditAddresslist[2],
              address1: place.name,
              address2: '',
              zip: '',
              city: '',
              stateAddress: '',
            };
            allowEditList[2] = {
              ...allowEditList[2],
              zip: true,
              city: true,
              stateAddress: true,
            };
            // address13.current.value = address1;
          }
        }

        const searched = [...checkoutState.searchedAdresses];
        searched[index] = true;

        setCheckoutState((checkoutState) => ({
          ...checkoutState,
          addresses: clientEditAddresslist,
          allowEditAdresses: allowEditList,
          searchedAdresses: searched,
        }));
        return;
      }
    }
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <SubscriptionForm />;
      case 1:
        return (
          <AddressForm
            state={checkoutState}
            setState={setCheckoutState}
            address11={address11}
            address12={address12}
            address13={address13}
            onPlaceChanged={onPlaceChanged}
            isLoaded={isLoaded}
            onLoad1={onLoad1}
            onLoad2={onLoad2}
            onLoad3={onLoad3}
          />
        );
      case 2:
        return <Review handleOpen={handleOpen} />;
      default:
        throw new Error('Unknown step');
    }
  }

  const descriptionElementRef = React.useRef(null);

  const validateStepForm = (setErrors = true) => {
    const errors = structuredClone(initialErrors);
    let disabled = false;
    const snackMessage = {
      display: false,
      message: '',
      type: 'info',
    };

    if (activeStep === 0) {
      if (
        checkoutState.products.find((element) => element.added === true) ===
        undefined
      ) {
        disabled = true;
        snackMessage.display = true;
        snackMessage.message = 'Please select your subscription';
        snackMessage.type = 'error';
      }
    }
    if (activeStep === 1) {
      const validPets = checkoutState.pets.some((pet) => pet.length === 0);
      disabled =
        !singleNameRegex.test(checkoutState.client.firstName) ||
        !singleNameRegex.test(checkoutState.client.lastName) ||
        !emailRegex.test(checkoutState.client.email) ||
        !matchIsValidTel(checkoutState.client.phone) ||
        checkoutState.addresses[0].address1.length === 0 ||
        checkoutState.addresses[0].zip.length === 0 ||
        checkoutState.addresses[0].city.length === 0 ||
        checkoutState.addresses[0].stateAddress.length === 0 ||
        checkoutState.addresses[1].address1.length === 0 ||
        checkoutState.addresses[1].zip.length === 0 ||
        checkoutState.addresses[1].city.length === 0 ||
        checkoutState.addresses[1].stateAddress.length === 0 ||
        checkoutState.addresses[2].address1.length === 0 ||
        checkoutState.addresses[2].zip.length === 0 ||
        checkoutState.addresses[2].city.length === 0 ||
        checkoutState.addresses[2].stateAddress.length === 0 ||
        validPets;

      for (let index = 0; index < checkoutState.pets.length; index++) {
        errors.pets.push('');
        if (!singleNameRegex.test(checkoutState.pets[index])) {
          errors.pets[index] = 'Invalid field';
        }
        if (checkoutState.pets[index].length === 0) {
          errors.pets[index] = 'Required field';
        }
      }
      if (!singleNameRegex.test(checkoutState.client.firstName)) {
        errors.client.firstName = 'Invalid field';
      }
      if (checkoutState.client.firstName.length === 0) {
        errors.client.firstName = 'Required field';
      }
      if (!singleNameRegex.test(checkoutState.client.lastName)) {
        errors.client.lastName = 'Invalid field';
      }
      if (checkoutState.client.lastName.length === 0) {
        errors.client.lastName = 'Required field';
      }
      if (!emailRegex.test(checkoutState.client.email)) {
        errors.client.email = 'Invalid field';
      }
      if (checkoutState.client.email.length === 0) {
        errors.client.email = 'Required field';
      }
      if (!matchIsValidTel(checkoutState.client.phone)) {
        errors.client.phone = 'Invalid field';
      }
      if (checkoutState.client.phone.length === 0) {
        errors.client.phone = 'Required field';
      }

      if (checkoutState.addresses[0].address1.length === 0) {
        errors.addresses[0].address1 = 'Required field';
      }
      if (checkoutState.addresses[0].zip.length === 0) {
        errors.addresses[0].zip = 'Required field';
      }
      if (checkoutState.addresses[0].city.length === 0) {
        errors.addresses[0].city = 'Required field';
      }
      if (checkoutState.addresses[0].stateAddress.length === 0) {
        errors.addresses[0].stateAddress = 'Required field';
      }
      if (checkoutState.addresses[1].address1.length === 0) {
        errors.addresses[1].address1 = 'Required field';
      }
      if (checkoutState.addresses[1].zip.length === 0) {
        errors.addresses[1].zip = 'Required field';
      }
      if (checkoutState.addresses[1].city.length === 0) {
        errors.addresses[1].city = 'Required field';
      }
      if (checkoutState.addresses[1].stateAddress.length === 0) {
        errors.addresses[1].stateAddress = 'Required field';
      }

      if (checkoutState.addresses[2].address1.length === 0) {
        errors.addresses[2].address1 = 'Required field';
      }
      if (checkoutState.addresses[2].zip.length === 0) {
        errors.addresses[2].zip = 'Required field';
      }
      if (checkoutState.addresses[2].city.length === 0) {
        errors.addresses[2].city = 'Required field';
      }
      if (checkoutState.addresses[2].stateAddress.length === 0) {
        errors.addresses[2].stateAddress = 'Required field';
      }
    }
    if (activeStep === 2) {
      disabled = !checkoutState.termsCheck;
      if (!checkoutState.termsCheck) {
        snackMessage.display = true;
        snackMessage.message =
          'You must accept the terms and condition to place your order';
        snackMessage.type = 'error';
      }
    }
    if (setErrors) {
      setCheckoutState((checkoutState) => ({
        ...checkoutState,
        errors,
        snackMessage,
      }));
    }
    return disabled;
  };

  useEffect(() => {
    const stepReady = validateStepForm(false);
    if (!stepReady) {
      setButtonColor('primaryTk');
    } else {
      setButtonColor('disabledButton');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkoutState.client,
    checkoutState.products,
    checkoutState.client,
    checkoutState.pets,
    checkoutState.addresses,
    checkoutState.payment,
    checkoutState.termsCheck,
    checkoutState.addressRepeat,
    activeStep,
  ]);

  const submitSubscription = async () => {
    if (requesting) {
      return;
    }
    setRequesting(true);
    const petsArray = checkoutState.pets.map((item) => {
      return {
        name: item,
      };
    });

    let total = 0;
    const productsArray = [...checkoutState.products];
    const index = productsArray.findIndex((element) => element.added === true);
    const additionalsQty = productsArray[index].businessCard
      ? productsArray[index].petQty
      : productsArray[index].petQty - 1;
    const additionalsPrice = additionalsQty * checkoutState.prices.additional;

    if (productsArray[index].businessCard) {
      total += checkoutState.prices.subscription;
      total += additionalsPrice;
    } else {
      total += checkoutState.prices.subscription;
      total += additionalsPrice;
    }

    const clearedAddresses = structuredClone(checkoutState.addresses);
    clearedAddresses.forEach((item) => {
      item.state = item.stateAddress;
      delete item.stateAddress;
    });

    const payload = {
      client: {
        isSubscriber: productsArray[index].businessCard,
        firstName: checkoutState.client.firstName,
        lastName: checkoutState.client.lastName,
        phone: removeWhiteSpaces(checkoutState.client.phone),
        email: checkoutState.client.email,
        addresses: clearedAddresses,
      },
      pets: [...petsArray],
      total: total,
    };

    const response = await landingSubscription(payload);
    if (response.success) {
      const paymentInfo = localStorage.getItem('paymentInfo');
      if (paymentInfo !== null) {
        localStorage.removeItem('paymentInfo');
      }
      const newPaymentInfo = {
        ...response.responseData,
        attempDate: new Date(),
      };
      localStorage.setItem('paymentInfo', JSON.stringify(newPaymentInfo));

      setCheckoutState((checkoutState) => ({
        ...checkoutState,
        subscriptionId: response?.responseData?.transactionId ?? '',
      }));

      window.location.assign(response.responseData?.url);
    } else {
      setCheckoutState((checkoutState) => ({
        ...checkoutState,
        snackMessage: {
          display: true,
          message: response.message,
          type: 'error',
        },
      }));
    }
    setRequesting(false);
    return response.success;
  };

  const handleNext = async () => {
    const disabled = validateStepForm();
    if (!disabled) {
      let success = true;
      if (activeStep === 0) {
        const petsEmpty = [];
        const plan = checkoutState.products.find(
          (element) => element.added === true
        );
        for (let index = 0; index < plan.petQty; index++) {
          petsEmpty.push('');
        }

        setCheckoutState((checkoutState) => ({
          ...checkoutState,
          pets: petsEmpty,
          errors: {
            ...checkoutState.errors,
            pets: petsEmpty,
          },
        }));
      }
      if (activeStep === 2) {
        success = await submitSubscription();
      }
      if (success) {
        setActiveStep(activeStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onloads = [onLoad1, onLoad2, onLoad3];
  const refs = [address11, address12, address13];

  return (
    <CheckoutContext.Provider
      value={{
        checkoutState,
        setCheckoutState,
        initialState,
        isLoaded,
        onloads,
        refs,
        onPlaceChanged,
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        <LandingNavBar />
        <Container
          component="main"
          maxWidth="false"
          sx={{ pt: 7, mb: 4, maxWidth: '650px' }}
        >
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Checkout
            </Typography>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Thank you for your order.
                </Typography>
                <Typography variant="subtitle1">
                  Your order number is #{checkoutState.subscriptionId}. We have
                  emailed your order confirmation, and will send you an update
                  when your order has shipped.
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {activeStep !== 0 && (
                    <Button
                      color="primaryTk"
                      onClick={handleBack}
                      sx={{ mt: 3, ml: 1, borderRadius: '30px' }}
                    >
                      Back
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    color={buttonColor}
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1, borderRadius: '30px' }}
                  >
                    {!requesting && (
                      <>
                        {activeStep === steps.length - 1
                          ? 'Place order'
                          : 'Next'}
                      </>
                    )}
                    {requesting && (
                      <CircularProgress color="white" size="1.3rem" />
                    )}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Paper>
        </Container>
        <LandingFooter />

        <Dialog
          open={open}
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            Terms and Conditions
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <span
                dangerouslySetInnerHTML={{ __html: termsAndConditions }}
              ></span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </CheckoutContext.Provider>
  );
};

export default React.memo(Checkout);
