import React, { useState, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../../css/style.css';
import logo from '../../image/logo.png';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../../App';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { loginRequestOtp, loginValidateOtp } from '../../config/api';
import { removeWhiteSpaces } from '../../utils/utils';
import { MuiTelInput } from 'mui-tel-input';
import { acceptedPhoneCountries } from '../../config/constants';
import { matchIsValidTel } from 'mui-tel-input';
import { Snackbar } from '@mui/material';

const defaultTheme = createTheme({
  palette: {
    white: {
      main: '#fff',
    },
  },
});
const LoginPage = () => {
  const initialState = {
    requesting: false,
    redirect: false,
    error: false,
    step: 1,
    usrId: '',
    message: '',
    phone: '',
    code: '',
    errors: {
      phone: '',
      code: '',
    },
  };
  const [state, setState] = useState(initialState);

  React.useEffect(() => {
    const authInfo = localStorage.getItem('authInfo');
    if (authInfo !== null) {
      localStorage.removeItem('authInfo');
    }
  }, []);

  const authContext = useContext(AuthContext);

  const validateForm = () => {
    let validForm = true;
    const errors = {
      phone: '',
      code: '',
    };
    if (state.step === 1) {
      validForm = matchIsValidTel(state.phone);
      if (!matchIsValidTel(state.phone)) {
        errors.phone = 'Invalid field';
      }
      if (state.phone.length === 0) {
        errors.phone = 'Required field';
      }
    }
    if (state.step === 2) {
      if (state.code.length === 0) {
        validForm = false;
        errors.code = 'Required field';
      }
    }
    setState((state) => ({
      ...state,
      errors,
    }));
    return validForm;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const validate = validateForm();
    if (state.requesting || !validate) {
      return;
    }

    setState((state) => ({
      ...state,
      requesting: true,
      error: false,
    }));

    try {
      const phone = removeWhiteSpaces(state.phone);
      const otpRequest = await loginRequestOtp(phone);
      if (otpRequest.success) {
        setState((state) => ({
          ...state,
          requesting: false,
          step: 2,
          // code: otpRequest.code,
          usrId: otpRequest.clientId,
        }));
      } else {
        setState((state) => ({
          ...state,
          requesting: false,
          error: true,
          message: otpRequest.message,
        }));
      }
    } catch (error) {
      console.error(error);
      setState((state) => ({
        ...state,
        requesting: false,
        error: true,
        message: JSON.stringify(error),
      }));
    }
  };

  const handleBack = () => {
    setState(initialState);
  };

  const handleFormSubmit2 = async (e) => {
    e.preventDefault();
    const validate = validateForm();
    if (state.requesting || !validate) {
      return;
    }

    setState((state) => ({
      ...state,
      requesting: true,
      error: false,
    }));

    try {
      const otpRequest = await loginValidateOtp(state.usrId, state.code);
      if (otpRequest.success) {
        otpRequest.decodedJwt = jwt_decode(otpRequest.accessToken);
        otpRequest.loginDate = new Date();

        authContext.setAuth({
          ...authContext.auth,
          authItem: otpRequest,
          loggedIn: true,
        });

        localStorage.setItem('authInfo', JSON.stringify(otpRequest));
        setState((state) => ({
          ...state,
          requesting: false,
          redirect: true,
        }));
      } else {
        setState((state) => ({
          ...state,
          requesting: false,
          error: true,
          message: otpRequest.message,
        }));
      }
    } catch (error) {
      console.error(error);
      setState((state) => ({
        ...state,
        requesting: false,
        error: true,
        message: JSON.stringify(error),
      }));
    }
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handlePhoneChange = (newPhone, info) => {
    setState((state) => ({
      ...state,
      phone: newPhone,
    }));
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    authContext.setAuth({
      ...authContext.auth,
      snackMessage: {
        display: false,
        message: '',
        type: 'info',
      },
    });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {state.redirect && <Navigate to="/profile" replace={true} />}
      <div className="login-container">
        <div className="login-wrap">
          <div className="Home-links">
            <img src={logo} width="45%" className="mx-auto mb-3" alt="" />
          </div>
          {state.step === 1 && (
            <form className="login_form">
              <div className="row px-5">
                <div className="col-lg-12 ">
                  <div className="form-group ">
                    <MuiTelInput
                      value={state.phone}
                      onChange={handlePhoneChange}
                      fullWidth
                      label="Phone"
                      id="phone"
                      name="phone"
                      size="normal"
                      variant="standard"
                      defaultCountry="US"
                      forceCallingCode
                      required
                      onlyCountries={acceptedPhoneCountries}
                      error={state.errors.phone !== ''}
                      helperText={state.errors.phone ?? 'Input Req.'}
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-12 mt-3">
                  <h4 className="h6 blue-heading">
                    Please enter your phone number and you'll be getting a code
                    via SMS
                  </h4>
                </div>

                {state.error && (
                  <div className="col-lg-12">
                    <Alert data-cy="errorAlert" severity="error">
                      {state.message}
                    </Alert>
                  </div>
                )}

                <div className="col-12 mt-3">
                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      onClick={handleFormSubmit}
                      className="btn btn-sm btn-hover theme-btn text-white text-uppercase login-btn"
                    >
                      {state.requesting && (
                        <CircularProgress color="white" size="1.3rem" />
                      )}
                      {!state.requesting && <>SUBMIT</>}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
          {state.step === 2 && (
            <form className="login_form">
              <div className="row px-5">
                <div className="col-lg-12 ">
                  <div className="form-group ">
                    <TextField
                      size="small"
                      value={state.code}
                      margin="normal"
                      required
                      fullWidth
                      name="code"
                      data-cy="code"
                      label="SMS Code"
                      type="code"
                      id="code"
                      onChange={handleInputChange}
                      error={state.errors.code !== ''}
                      helperText={state.errors.code}
                      inputProps={{ maxLength: 6 }}
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-12 mt-3">
                  <h4 className="h6 blue-heading">
                    Please enter the code you received via SMS
                  </h4>
                </div>

                {state.error && (
                  <div className="col-lg-12">
                    <Alert data-cy="errorAlert" severity="error">
                      {state.message}
                    </Alert>
                  </div>
                )}

                <div className="col-12 mt-3">
                  <div className="d-flex justify-content-center">
                    <button
                      type="button"
                      onClick={handleBack}
                      className="btn btn-sm btn-hover theme-btn text-white text-uppercase mx-1"
                    >
                      BACK
                    </button>
                    <button
                      type="submit"
                      onClick={handleFormSubmit2}
                      className="btn btn-sm btn-hover theme-btn text-white text-uppercase login-btn mx-1"
                    >
                      {state.requesting && (
                        <CircularProgress color="white" size="1.3rem" />
                      )}
                      {!state.requesting && <>LOGIN</>}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
      <Snackbar
        open={authContext.auth.snackMessage.display}
        autoHideDuration={8000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={authContext.auth.snackMessage.type}
          sx={{ width: '100%' }}
        >
          {authContext.auth.snackMessage.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default LoginPage;
