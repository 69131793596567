import React from "react";
import { Stack } from "@mui/material";
import Fab from "@mui/material/Fab";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from 'prop-types';

const FloatingActions = ({update, onEditClick, updating}) => {
  const fabContainerBox = {
    position: "fixed",
    bottom: 16,
    right: 16,
  };
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      sx={fabContainerBox}
      spacing={0.5}
    >
      <Fab size="medium" color="primaryTk" onClick={update}>
        {updating && <CircularProgress color="white" size="1.3rem" />}
        {!updating && <SaveIcon color="primaryTk.contrastText" />}
      </Fab>
      <Fab size="medium" color="primaryTk" onClick={onEditClick}>
        <CancelIcon color="primaryTk.contrastText" />
      </Fab>
    </Stack>
  );
};

FloatingActions.propTypes = {
  update: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
}

export default FloatingActions;
