import React, { useEffect, useContext } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { ProfileContext } from '../Profile';
import {
  Alert,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Autocomplete } from '@react-google-maps/api';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  paySubscription,
  renewSubscription,
  rebuySubscription,
} from '../../../config/api'; //, updateAddress
import { AuthContext } from '../../../App';
import { termsAndConditions } from '../../../config/terms';

export default function CheckoutForm({
  item,
  subscriptionId,
  state,
  setState,
  isRebuying,
}) {
  const [step, setStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const descriptionElementRef = React.useRef(null);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function getStepContent(activeStep) {
    switch (activeStep) {
      case 0:
        return <CreditCardForm step={step} setStep={setStep} />;
      case 1:
        return (
          <Review
            step={step}
            setStep={setStep}
            handleOpen={handleOpen}
            item={item}
            subscriptionId={subscriptionId}
            state={state}
            setState={setState}
            isRebuying={isRebuying}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  }
  return (
    <>
      <Grid container spacing={2} sx={{ px: { sm: 0, md: 5 } }}>
        <Grid item xs={12} md={12} sx={{ mx: { sm: 0, md: 5 } }}>
          {getStepContent(step)}
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Terms and Conditions</DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <span
              dangerouslySetInnerHTML={{ __html: termsAndConditions }}
            ></span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const CreditCardForm = ({ step, setStep }) => {
  const checkoutContext = useContext(ProfileContext);

  const handleAddressChange = (event, index, attribute) => {
    if (checkoutContext.checkoutState.addressRepeat[index]) {
      return;
    }
    const value = event.target.value;
    const clientEditAddresslist = [...checkoutContext.checkoutState.addresses];
    clientEditAddresslist[index][attribute] = value;
    if (index === 0) {
      if (checkoutContext.checkoutState.addressRepeat[1]) {
        clientEditAddresslist[1][attribute] = value;
      }
      if (checkoutContext.checkoutState.addressRepeat[2]) {
        clientEditAddresslist[2][attribute] = value;
      }
    }
    const searched = [...checkoutContext.checkoutState.searchedAdresses];
    if (attribute === 'address1') {
      searched[index] = false;
    }
    checkoutContext.setCheckoutState({
      ...checkoutContext.checkoutState,
      addresses: clientEditAddresslist,
      searchedAdresses: searched,
    });
  };

  const sameAsBusiness = (index) => {
    if (index === 0) {
      return; // failsafe, just in case
    }

    const clientEditAddresslist = [...checkoutContext.checkoutState.addresses];
    const repeatArray = [...checkoutContext.checkoutState.addressRepeat];
    const allowEditList = [...checkoutContext.checkoutState.allowEditAdresses];
    const value = !repeatArray[index];
    repeatArray[index] = value;
    let addressLine1 = '';

    if (value) {
      clientEditAddresslist[index] = {
        ...clientEditAddresslist[index],
        address1: checkoutContext.checkoutState.addresses[0].address1,
        address2: checkoutContext.checkoutState.addresses[0].address2,
        city: checkoutContext.checkoutState.addresses[0].city,
        stateAddress: checkoutContext.checkoutState.addresses[0].stateAddress,
        zip: checkoutContext.checkoutState.addresses[0].zip,
      };
      addressLine1 = checkoutContext.checkoutState.addresses[0].address1;
      checkoutContext.refs[index].current.value = addressLine1;
    } else {
      clientEditAddresslist[index] = {
        ...clientEditAddresslist[index],
        address1: checkoutContext.checkoutState.addresses[index].address1 ?? '',
        address2: checkoutContext.checkoutState.addresses[index].address2 ?? '',
        city: checkoutContext.checkoutState.addresses[index].city ?? '',
        stateAddress:
          checkoutContext.checkoutState.addresses[index].stateAddress ?? '',
        zip: checkoutContext.checkoutState.addresses[index].zip ?? '',
      };
      addressLine1 = checkoutContext.checkoutState.addresses[index].address1;
      allowEditList[index] = {
        ...allowEditList[index],
        zip: false,
        city: false,
        stateAddress: false,
      };
    }

    checkoutContext.setCheckoutState({
      ...checkoutContext.checkoutState,
      addressRepeat: repeatArray,
      addresses: clientEditAddresslist,
      allowEditAdresses: allowEditList,
    });
  };

  const handleOnblur = (index) => {
    if (!checkoutContext.checkoutState.searchedAdresses[index]) {
      const allowEditList = [
        ...checkoutContext.checkoutState.allowEditAdresses,
      ];
      allowEditList[index] = {
        ...allowEditList[index],
        zip: true,
        city: true,
        stateAddress: true,
      };
      checkoutContext.setCheckoutState({
        ...checkoutContext.checkoutState,
        allowEditAdresses: allowEditList,
      });
    }
  };

  const validateStepForm = () => {
    const errors = structuredClone(checkoutContext.initialCheckoutState);
    let disabled = false;
    const snackMessage = {
      display: false,
      message: '',
      type: 'info',
    };

    if (step === 0) {
      disabled =
        checkoutContext.checkoutState.addresses[2].address1.length === 0 ||
        checkoutContext.checkoutState.addresses[2].zip.length === 0 ||
        checkoutContext.checkoutState.addresses[2].city.length === 0 ||
        checkoutContext.checkoutState.addresses[2].stateAddress.length === 0;

      if (checkoutContext.checkoutState.addresses[2].address1.length === 0) {
        errors.addresses[2].address1 = 'Required field';
      }
      if (checkoutContext.checkoutState.addresses[2].zip.length === 0) {
        errors.addresses[2].zip = 'Required field';
      }
      if (checkoutContext.checkoutState.addresses[2].city.length === 0) {
        errors.addresses[2].city = 'Required field';
      }
      if (
        checkoutContext.checkoutState.addresses[2].stateAddress.length === 0
      ) {
        errors.addresses[2].stateAddress = 'Required field';
      }
    }
    checkoutContext.setCheckoutState(() => ({
      ...checkoutContext.checkoutState,
      errors,
      snackMessage,
    }));
    return disabled;
  };

  const handleNext = () => {
    const disabled = validateStepForm();
    if (disabled) {
      return;
    }
    setStep(1);
  };

  return (
    <Paper elevation={4} sx={{ mt: 3, px: 3, py: 2 }}>
      <Typography
        variant="h5"
        component="h3"
        color="#33428C"
        sx={{
          fontWeight: 'bold',
          fontSize: '1.8rem',
          textAlign: 'center',
          my: 2,
        }}
      >
        Payment Info
      </Typography>

      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12}>
          <AddressFormItem
            item={checkoutContext.checkoutState.addresses[2]}
            index={2}
            stateItem="addresses"
            errors={checkoutContext.checkoutState.errors}
            onChange={handleAddressChange}
            isLoaded={checkoutContext.isLoaded}
            onLoad={checkoutContext.onloads[2]}
            address1Ref={checkoutContext.refs[2]}
            onPlaceChanged={checkoutContext.onPlaceChanged}
            sameAsBusiness={sameAsBusiness}
            repeatValue={checkoutContext.checkoutState.addressRepeat[2]}
            allowEditField={checkoutContext.checkoutState.allowEditAdresses[2]}
            handleOnblur={handleOnblur}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primaryTk"
            onClick={handleNext}
            sx={{ borderRadius: '30px' }}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

const AddressFormItem = ({
  item,
  index,
  stateItem,
  errors,
  onChange,
  isLoaded,
  onLoad,
  onPlaceChanged,
  address1Ref,
  repeatValue,
  sameAsBusiness,
  allowEditField,
  handleOnblur,
}) => {
  const checkoutContext = useContext(ProfileContext);

  useEffect(() => {
    if (isLoaded && item.address1 !== '' && address1Ref?.current !== null) {
      address1Ref.current.value = item.address1;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  useEffect(() => {
    if (!repeatValue) {
      address1Ref.current.value = item.address1;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repeatValue]);

  const handleFocus = (index) => {
    const errors = structuredClone(checkoutContext.checkoutState.errors);
    const addressesErrorList = [...errors.addresses];
    addressesErrorList[index] = {
      ...addressesErrorList[index],
      address1: '',
      zip: '',
      city: '',
      stateAddress: '',
    };
    checkoutContext.setCheckoutState({
      ...checkoutContext.checkoutState,
      errors: {
        ...checkoutContext.checkoutState.errors,
        addresses: [...addressesErrorList],
      },
    });
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={1}
      sx={{ width: 1, mb: 3 }}
    >
      <Stack
        direction={{ xs: 'column', lg: 'row' }}
        justifyContent="flex-start"
        alignItems={{ xs: 'flex-start', lg: 'center' }}
        spacing={2}
      >
        <Typography
          variant="h6"
          className="item-subtitle"
          gutterBottom
          sx={{
            fontWeight: 'bold',
          }}
        >
          {item.type} Address:
        </Typography>
        {item.type !== 'business' && (
          <FormControlLabel
            control={
              <Checkbox
                checked={repeatValue}
                onChange={() => sameAsBusiness(index)}
                size="small"
              />
            }
            label="Use same as Business "
          />
        )}
      </Stack>
      {!repeatValue && (
        <>
          <Stack
            direction={{ xs: 'column', lg: 'row' }}
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{ width: 1 }}
          >
            <Box sx={{ width: { xs: 1, lg: 0.5 } }}>
              {isLoaded ? (
                <Autocomplete
                  onLoad={onLoad}
                  onPlaceChanged={() => onPlaceChanged(index)}
                  restrictions={{ country: ['us'] }}
                >
                  <TextField
                    size="small"
                    variant="standard"
                    margin="normal"
                    required
                    disabled={repeatValue}
                    label="Address Line 1"
                    className="item-subtitle"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    inputProps={{ ref: address1Ref }}
                    onFocus={() => handleFocus(index)}
                    error={errors[stateItem][index].address1 !== ''}
                    helperText={
                      errors[stateItem][index].address1 ?? 'Input Req.'
                    }
                    onBlur={() => {
                      handleOnblur(index);
                    }}
                    onChange={(event) => {
                      onChange(event, index, 'address1');
                    }}
                  />
                </Autocomplete>
              ) : (
                <CircularProgress size="1.5rem" />
              )}
            </Box>
            <TextField
              size="small"
              variant="standard"
              value={item.address2}
              onChange={(event) => {
                onChange(event, index, 'address2');
              }}
              disabled={repeatValue}
              sx={{ width: { xs: 1, lg: 0.5 } }}
              label="Address Line 2"
              className="item-subtitle"
              InputLabelProps={{ shrink: true }}
              error={errors[stateItem][index].address2 !== ''}
              helperText={errors[stateItem][index].address2 ?? 'Input Req.'}
            />
          </Stack>
          <Stack
            direction={{ xs: 'column', lg: 'row' }}
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{ width: 1 }}
          >
            <TextField
              size="small"
              variant="standard"
              required
              value={item.city}
              disabled={!allowEditField.city || repeatValue}
              onChange={(event) => {
                onChange(event, index, 'city');
              }}
              sx={{ width: { xs: 1, lg: 0.34 } }}
              label="City"
              className="item-subtitle"
              InputLabelProps={{ shrink: true }}
              error={errors[stateItem][index].city !== ''}
              helperText={errors[stateItem][index].city ?? 'Input Req.'}
            />
            <TextField
              size="small"
              variant="standard"
              required
              value={item.stateAddress}
              disabled={!allowEditField.stateAddress || repeatValue}
              onChange={(event) => {
                onChange(event, index, 'stateAddress');
              }}
              sx={{ width: { xs: 1, lg: 0.33 } }}
              label="State"
              className="item-subtitle"
              InputLabelProps={{ shrink: true }}
              error={errors[stateItem][index].stateAddress !== ''}
              helperText={errors[stateItem][index].stateAddress ?? 'Input Req.'}
            />
            <TextField
              size="small"
              variant="standard"
              required
              value={item.zip}
              onChange={(event) => {
                onChange(event, index, 'zip');
              }}
              disabled={!allowEditField.zip || repeatValue}
              sx={{ width: { xs: 1, lg: 0.33 } }}
              label="Zip Code"
              className="item-subtitle"
              InputLabelProps={{ shrink: true }}
              error={errors[stateItem][index].zip !== ''}
              helperText={errors[stateItem][index].zip ?? 'Input Req.'}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};

const Review = ({
  step,
  setStep,
  handleOpen,
  item,
  subscriptionId,
  state,
  setState,
  isRebuying,
}) => {
  const authContext = useContext(AuthContext);

  const checkoutContext = useContext(ProfileContext);
  const billingAddress = [
    `${checkoutContext.checkoutState.addresses[2].address1} ${
      checkoutContext.checkoutState.addresses[2].address2 ?? ''
    }`,
    checkoutContext.checkoutState.addresses[2].city,
    checkoutContext.checkoutState.addresses[2].stateAddress,
    checkoutContext.checkoutState.addresses[2].zip,
  ];
  const handleAcceptTerms = () => {
    const value = !checkoutContext.checkoutState.termsCheck;
    checkoutContext.setCheckoutState({
      ...checkoutContext.checkoutState,
      termsCheck: value,
      snackMessage: {
        display: false,
        message: '',
        type: 'info',
      },
    });
  };

  useEffect(() => {
    checkoutContext.setCheckoutState({
      ...checkoutContext.checkoutState,
      termsCheck: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let total = 0;

  let cart = [];
  if (item !== null || isRebuying) {
    cart = state.subscriptions[0].processedDetails[item].details;
  } else {
    cart = state.subscriptions[0].renewDetail;
  }

  const hasFreeSubscription = state.client.hasFreeSubscription;

  cart.forEach((element, index) => {
    if (hasFreeSubscription) {
      cart[index].amount = 0;
    } else {
      total += element.amount;
    }
  });

  const getItemType = (string) => {
    switch (string) {
      case 'new':
        return 'New Subscription';
      case 'renew':
        return 'Subscription Renewal';
      case 'additional':
        return 'Additional Card';
      case 'replacement':
        return 'Card Replacement';
      default:
        break;
    }
  };

  const validateStepForm = () => {
    const errors = structuredClone(checkoutContext.initialCheckoutState);
    let disabled = false;
    const snackMessage = {
      display: false,
      message: '',
      type: 'info',
    };

    if (step === 1) {
      disabled = !checkoutContext.checkoutState.termsCheck;
      if (!checkoutContext.checkoutState.termsCheck) {
        snackMessage.display = true;
        snackMessage.message =
          'You must accept the terms and condition to place your order';
        snackMessage.type = 'error';
      }
    }
    checkoutContext.setCheckoutState(() => ({
      ...checkoutContext.checkoutState,
      errors,
      snackMessage,
    }));
    return disabled;
  };

  const handleNext = async () => {
    const token = authContext.auth.authItem.accessToken;
    const disabled = validateStepForm();
    if (disabled) {
      return;
    }
    setState((state) => ({
      ...state,
      requesting: true,
    }));
    const payload = {
      subscriptionId,
      detail: cart,
      total,
      zip: checkoutContext.checkoutState.addresses[2].zip,
      email: checkoutContext.checkoutState.client.email,
    };

    let response;

    if (!isRebuying) {
      response =
        item !== null
          ? await paySubscription(payload, token)
          : await renewSubscription(subscriptionId, token);
    } else {
      response = await rebuySubscription(subscriptionId, token);
    }

    if (response.success) {
      const paymentInfo = localStorage.getItem('paymentInfo');
      if (paymentInfo !== null) {
        localStorage.removeItem('paymentInfo');
      }
      const newPaymentInfo = {
        ...response.responseData,
        attempDate: new Date(),
      };
      localStorage.setItem('paymentInfo', JSON.stringify(newPaymentInfo));
      setState((state) => ({
        ...state,
        requesting: false,
      }));
      window.location.assign(response.responseData?.url);
      // setStep(2);
    } else {
      setState((state) => ({
        ...state,
        requesting: false,
        snackMessage: {
          display: true,
          message: 'There was a problem creating your payment',
          type: 'error',
        },
      }));
    }
  };

  return (
    <Paper elevation={4} sx={{ mt: 3, px: 3, py: 2 }}>
      <Typography
        variant="h5"
        component="h3"
        color="#33428C"
        sx={{
          fontWeight: 'bold',
          fontSize: '1.8rem',
          textAlign: 'center',
          my: 2,
        }}
      >
        Order summary
      </Typography>

      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: 'bold',
            }}
          >
            Items summary
          </Typography>
          <List disablePadding>
            {cart.map((cartItem, index) => {
              return (
                <ListItem
                  key={`${subscriptionId}-${index}`}
                  sx={{ py: 1, px: 0 }}
                >
                  <ListItemText
                    primary={getItemType(cartItem.type)}
                    secondary={`${
                      cartItem.petId === null ? 'Business Card' : 'Pet Card'
                    } x1`}
                  />
                  <Typography variant="body2">
                    ${parseFloat(cartItem.amount).toFixed(2)}
                  </Typography>
                </ListItem>
              );
            })}

            <ListItem sx={{ py: 1, px: 0 }}>
              <ListItemText primary="Total" />
              <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                ${parseFloat(total).toFixed(2)}
              </Typography>
            </ListItem>
          </List>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ mt: 2, fontWeight: 'bold' }}
              >
                Billing Address
              </Typography>
              <Typography
                gutterBottom
              >{`${checkoutContext.checkoutState.client.firstName} ${checkoutContext.checkoutState.client.lastName}`}</Typography>
              <Typography gutterBottom>{billingAddress.join(', ')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Checkbox
                  onChange={handleAcceptTerms}
                  checked={checkoutContext.checkoutState.termsCheck}
                  color="secondary"
                  name="saveAddress"
                />
                <span>
                  I agree with the{' '}
                  <Button variant="text" onClick={handleOpen}>
                    Terms and Conditions
                  </Button>
                </span>
              </Box>
            </Grid>
          </Grid>

          {checkoutContext.checkoutState.snackMessage.display && (
            <Alert severity={checkoutContext.checkoutState.snackMessage.type}>
              {checkoutContext.checkoutState.snackMessage.message}
            </Alert>
          )}
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primaryTk"
            onClick={() => {
              setStep(0);
            }}
            sx={{ borderRadius: '30px' }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primaryTk"
            onClick={handleNext}
            sx={{ ml: 1, borderRadius: '30px' }}
          >
            {!state.requesting && <>Submit</>}
            {state.requesting && (
              <CircularProgress color="white" size="1.5rem" />
            )}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};
