import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import logo from "../../../image/logo.png";
import "../../../css/materialdesignicons.min.css";
import "../../../css/animate.css";
import "../../../css/style.css";
import "../style.css";

const LandingFooter = () => {
  return (
    <footer className="footer theme-bg overflow-hidden">
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="footer-top">
              <div className="foot_logo d-flex align-items-center justify-content-center">
                <img src={logo} className="img-fluid d-block" alt="" />
              </div>
              <p className="ftr-about">
              Get the business card that makes a memorable impression.
              </p>
            </div>
          </div>
          <div className="col-lg-12">
            <ul className="footer_menu_list list-unstyled mb-0">
              <li>
                <a href={`${window.location.origin}/`} className="nav-link">
                Home.
              </a>
              </li>
              <li>
                <a href={`${window.location.origin}/#howtoget`} className="nav-link">
                How To Get.
              </a>
              </li>
              <li>
                <a href={`${window.location.origin}/#buy`} className="nav-link">
                Buy Now.
              </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-12">
            <div className="copyrights">
              <p className="mb-0 text-center">
                2023 &copy;
                <span className="text-white font-weight-bold">TapKards.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default LandingFooter;
