import React from "react";
import Typography from "@mui/material/Typography";
import { Stack, Container } from "@mui/material";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PropTypes from "prop-types";
import HeaderSection from "../cards/sections/HeaderSection";

const NotFound = ({ message }) => {
  let theme = createTheme({
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <div id="bg" />
      <Container maxWidth="xs" sx={{ mt: 15, pb: 8 }}>
        <HeaderSection variant="error" />
        <Card
          variant="outlined"
          sx={{ mt: 3, minWidth: 1 }}
          className="main-card"
        >
          <CardContent>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={3}
            >
              <Typography variant="h1" align="center" component="h1">
                Sorry!
              </Typography>
              <Typography variant="h3" align="left" component="h3">
                Oops, looks like we couldn't find what you're looking for.
              </Typography>
              <Typography variant="h4" align="left" component="h4">
                {message}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={0}
          sx={{ pt: 0.5 }}
        ></Stack>
      </Container>
    </ThemeProvider>
  );
};

NotFound.defaultProps = {
  message: "",
};

NotFound.propTypes = {
  message: PropTypes.string,
};

export default NotFound;
