import React from "react";
import Typography from "@mui/material/Typography";
import { Stack, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import Avatar from "@mui/material/Avatar";
import Fab from "@mui/material/Fab";
import { AuthContext } from "../../../App";
import { updateImage } from "../../../config/api";
import CancelIcon from "@mui/icons-material/Cancel";

const DisplayPetsInfo = ({ state, setState, setOpenCancelPet, setPetIndexCancel }) => {
  const authContext = React.useContext(AuthContext);
  const refs = React.useRef([]);
  
  const updateFiles = async (index, file) => {
    try {
      const token = authContext.auth.authItem.accessToken;
      const id = state.pets[index].petId;
      const response = await updateImage(token, id, file[0], "pet");
      if (response.success) {
        const petslist = [...state.pets];
        petslist[index].img64 = response.responseData.img64;
        petslist[index].image = response.responseData.image;

        setState((state) => ({
          ...state,
          editing: false,
          requesting: false,
          pets: petslist,
          snackMessage: {
            display: true,
            message: "Your information was successfully updated",
            type: "success",
          },
        }));
      } else {
        setState((state) => ({
          ...state,
          editing: false,
          requesting: false,
          snackMessage: {
            display: true,
            message:
              response?.message ??
              "There was a problem updating the pets info, please try again",
            type: "error",
          },
        }));
      }
    } catch (error) {
      console.error(error);
      setState((state) => ({
        ...state,
        requesting: false,
        error: true,
        message: JSON.stringify(error.message),
      }));
    }
  };
  return (
    <Paper elevation={4} sx={{ mt: 3, px: 3, py: 2 }}>
      <Typography
        variant="h5"
        component="h3"
        sx={{ fontWeight: "regular", mb: 4 }}
      >
        Pets information
      </Typography>
      {state.pets.length > 0 && (
        <Grid container spacing={5} sx={{ px: 5, mb: 3 }}>
          {state.pets.map((pet, index) => {
            return (
              <Grid item xs={12} md={4} lg={4} key={pet.petId}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-end"
                    spacing={0}
                  >
                    <Avatar
                      src={pet.img64?.imgageBase64 ?? ""}
                      sx={{
                        width: 120,
                        height: 120,
                      }}
                    />
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      alignItems="flex-end"
                      spacing={0}
                      sx={{
                        height: 120,
                      }}
                    >
                      {pet.unpaid && (
                        <Fab
                          sx={{
                            width: 36,
                            height: 36,
                            ml: -4,
                          }}
                          aria-label="edit"
                          color="error"
                          onClick={() => {setPetIndexCancel(index); setOpenCancelPet(true)}}
                        >
                          <CancelIcon color="white" />
                        </Fab>
                      )}
                      {!pet.unpaid && <span></span>}
                      <>
                        <Fab
                          sx={{
                            width: 36,
                            height: 36,
                            ml: -4,
                          }}
                          aria-label="edit"
                          color="primaryTk"
                          onClick={() => refs.current[index].click()}
                        >
                          <EditIcon color="primaryTk.contrastText" />
                        </Fab>
                        <input
                          type="file"
                          className="d-none"
                          onChange={(e) => updateFiles(index, e.target.files)}
                          ref={(ref) =>
                            !refs.current.includes(ref) &&
                            refs.current.push(ref)
                          }
                        />
                      </>
                    </Stack>
                  </Stack>
                  <Typography
                    variant="h6"
                    component="h3"
                    sx={{ fontWeight: "regular" }}
                  >
                    {pet.name}
                  </Typography>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Paper>
  );
};
export default DisplayPetsInfo;
