import React, { useState, useEffect, useContext } from 'react';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';
import logo from '../../image/logo.png';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import NoMatch from './NoMatch';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../App';

let defaultTheme = createTheme({
  palette: {
    white: {
      main: '#fff',
    },
  },
});
defaultTheme = responsiveFontSizes(defaultTheme);

const PaymentResult = () => {
  const authContext = useContext(AuthContext);
  const [type, setType] = useState(undefined);
  const [transactionId, setTransactionId] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const success = urlParams.get('success');
    const canceled = urlParams.get('canceled');
    const paymentInfo = localStorage.getItem('paymentInfo');
    let localTransactionId = '';
    if (paymentInfo !== null) {
      const paymentObject = JSON.parse(paymentInfo);
      localTransactionId = paymentObject.transactionId;
      setTransactionId(paymentObject.transactionId);
    }
    if ((success === null && canceled === null) || localTransactionId === '') {
      setType('error');
    } else {
      setType(success ? 'success' : canceled ? 'canceled' : 'error');
    }
    localStorage.removeItem('paymentInfo');
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      {type === 'error' && <NoMatch />}
      <div className="login-container">
        <div className="login-wrap">
          <div className="Home-links">
            <img src={logo} width="45%" className="mx-auto mb-3" alt="" />
          </div>

          <div className="row px-5">
            <div className="col-sm-12 col-12 my-3">
              {type === 'success' && (
                <Box>
                  <Typography
                    variant="h5"
                    component="h3"
                    color="#33428C"
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.8rem',
                      textAlign: 'center',
                      mb: 2,
                    }}
                  >
                    Your Order has been placed successfully.
                  </Typography>
                  <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    Confirmation <b>#{transactionId}</b>. Shortly we will be
                    starting to print and ship your TapKard.
                  </Typography>
                  <Typography variant="subtitle1">Thank you.</Typography>
                </Box>
              )}
              {type === 'canceled' && (
                <h4 className="h6 blue-heading">
                  There was an error processing your payment or you canceled it.
                  Please try again, if the problem persists check your email.
                </h4>
              )}
            </div>

            <div className="col-12 mt-3 ">
              <div className="d-flex justify-content-center">
                <Link to="/profile" className="text-light text-center">
                  <button className="btn btn-sm btn-hover theme-btn text-white text-uppercase login-btn">
                    Go to the {authContext.auth.loggedIn && <>profile</>}
                    {!authContext.auth.loggedIn && <>login</>} page
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default PaymentResult;
