import { Backdrop, Box, CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";
import logo from "../../image/logo.png";

const Loader = () => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
      className="footer theme-bg loader"
    >
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Box sx={{ maxWidth: { xs: 0.7, md: "0.4", lg: "0.2" } }}>
          <img src={logo} className="img-fluid" alt="tapkards logo" />
        </Box>
        <CircularProgress color="inherit" />
        <Typography
          variant="body2"
          component="p"
        >
          Loading...
        </Typography>
      </Stack>
    </Backdrop>
  );
};

export default Loader;
