import React, { useEffect, useRef, useContext } from "react";
import { phoneTypes, emailTypes } from "../../../config/constants";
import Typography from "@mui/material/Typography";
import { Stack, Paper } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MailIcon from "@mui/icons-material/Mail";
import PublicIcon from "@mui/icons-material/Public";
import RoomIcon from "@mui/icons-material/Room";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { MuiTelInput } from "mui-tel-input";
import { acceptedPhoneCountries, libraries } from "../../../config/constants";
import { useJsApiLoader } from "@react-google-maps/api";
import { AuthContext } from "../../../App";
import { ItemEdit, AddressItem } from "./ClientForm";

const FormClientInfo = ({ state, setState, initializeErrors }) => {
  const authContext = useContext(AuthContext);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: authContext.auth.authItem.decodedJwt.googleApiKey,
    libraries,
  });

  const handleGeneralChange = (event, attribute) => {
    const value = event.target.value;
    setState((state) => ({
      ...state,
      clientEdit: {
        ...state.clientEdit,
        [attribute]: value,
      },
    }));
  };

  const handleGeneralPhoneChange = (newPhone, info) => {
    setState((state) => ({
      ...state,
      clientEdit: {
        ...state.clientEdit,
        phone: newPhone,
      },
    }));
  };

  const addPhoneItem = () => {
    const clientEditPhonelist = [...state.clientEdit.phoneList];
    clientEditPhonelist.push({
      type: "",
      value: "",
      isEditable: true,
      isPreferred: false,
    });
    const clientEdit = {
      ...state.clientEdit,
      phoneList: clientEditPhonelist,
    };
    setState((state) => ({
      ...state,
      clientEdit,
    }));

    initializeErrors(clientEdit);
  };

  const removePhoneItem = (index) => {
    const clientEditPhonelist = [...state.clientEdit.phoneList];
    clientEditPhonelist.splice(index, 1);
    const clientEdit = {
      ...state.clientEdit,
      phoneList: clientEditPhonelist,
    };
    setState((state) => ({
      ...state,
      clientEdit,
    }));

    initializeErrors(clientEdit);
  };

  const handlePhoneChange = (event, index, attribute) => {
    const value = event.target.value;
    if (attribute === "value") {
      const value = event.target.value;
      const last = event.target.value.length - 1;
      if (
        Number.isNaN(parseInt(value[last])) &&
        value[last] !== "+" &&
        value !== ""
      ) {
        return;
      }
    }
    const clientEditPhonelist = [...state.clientEdit.phoneList];
    clientEditPhonelist[index][attribute] = value;
    setState((state) => ({
      ...state,
      clientEdit: {
        ...state.clientEdit,
        phoneList: clientEditPhonelist,
      },
    }));
  };

  const handlePhoneValueChange = (newPhone, info, index, attribute) => {
    const clientEditPhonelist = [...state.clientEdit.phoneList];
    clientEditPhonelist[index][attribute] = newPhone;
    setState((state) => ({
      ...state,
      clientEdit: {
        ...state.clientEdit,
        phoneList: clientEditPhonelist,
      },
    }));
  };

  const addEmailItem = () => {
    const clientEditEmaillist = [...state.clientEdit.emailList];
    clientEditEmaillist.push({
      type: "",
      value: "",
      isEditable: true,
      isPreferred: false,
    });
    const clientEdit = {
      ...state.clientEdit,
      emailList: clientEditEmaillist,
    };
    setState((state) => ({
      ...state,
      clientEdit,
    }));
    initializeErrors(clientEdit);
  };

  const removeEmailItem = (index) => {
    const clientEditEmaillist = [...state.clientEdit.emailList];
    clientEditEmaillist.splice(index, 1);
    const clientEdit = {
      ...state.clientEdit,
      emailList: clientEditEmaillist,
    };
    setState((state) => ({
      ...state,
      clientEdit,
    }));
    initializeErrors(clientEdit);
  };

  const handleEmailChange = (event, index, attribute) => {
    const value = event.target.value;
    const clientEditEmaillist = [...state.clientEdit.emailList];
    clientEditEmaillist[index][attribute] = value;
    setState((state) => ({
      ...state,
      clientEdit: {
        ...state.clientEdit,
        emailList: clientEditEmaillist,
      },
    }));
  };

  const addMediaItem = () => {
    const clientEditMedialist = [...state.clientEdit.mediaList];
    clientEditMedialist.push({
      type: "",
      value: "",
      url: "",
      svg: "",
      isEditable: true,
      isPreferred: false,
    });
    const clientEdit = {
      ...state.clientEdit,
      mediaList: clientEditMedialist,
    };
    setState((state) => ({
      ...state,
      clientEdit,
    }));
    initializeErrors(clientEdit);
  };

  const removeMediaItem = (index) => {
    const clientEditMedialist = [...state.clientEdit.mediaList];
    clientEditMedialist.splice(index, 1);
    const clientEdit = {
      ...state.clientEdit,
      mediaList: clientEditMedialist,
    };
    setState((state) => ({
      ...state,
      clientEdit,
    }));
    initializeErrors(clientEdit);
  };

  const handleSocialChange = (event, index, attribute) => {
    const value = event.target.value;
    const clientEditMedialist = [...state.clientEdit.mediaList];
    clientEditMedialist[index][attribute] = value;
    if (attribute === "type") {
      const mediaType = state.data.mediaTypes[value];
      clientEditMedialist[index].svg = mediaType.svg;
      clientEditMedialist[index].url = mediaType.url;
    }
    setState((state) => ({
      ...state,
      clientEdit: {
        ...state.clientEdit,
        mediaList: clientEditMedialist,
      },
    }));
  };

  const handleAddressChange = (event, index, attribute) => {
    if (state.addressRepeat[index]) {
      return;
    }
    const value = event.target.value;
    const clientEditAddresslist = [...state.clientEdit.addresses];
    clientEditAddresslist[index][attribute] = value;
    if (index === 0) {
      if (state.addressRepeat[1]) {
        clientEditAddresslist[1][attribute] = value;
      }
      if (state.addressRepeat[2]) {
        clientEditAddresslist[2][attribute] = value;
      }
    }
    setState((state) => ({
      ...state,
      clientEdit: {
        ...state.clientEdit,
        addresses: clientEditAddresslist,
      },
    }));
  };

  const sameAsBusiness = (index) => {
    if (index === 0) {
      return; // failsafe, just in case
    }

    const clientEditAddresslist = [...state.clientEdit.addresses];
    const repeatArray = [...state.addressRepeat];
    const value = !repeatArray[index];
    repeatArray[index] = value;
    let addressLine1 = "";

    if (value) {
      clientEditAddresslist[index] = {
        ...clientEditAddresslist[index],
        address1: state.clientEdit.addresses[0].address1,
        address2: state.clientEdit.addresses[0].address2,
        city: state.clientEdit.addresses[0].city,
        state: state.clientEdit.addresses[0].state,
        zip: state.clientEdit.addresses[0].zip,
      };
      addressLine1 = state.clientEdit.addresses[0].address1;
    } else {
      clientEditAddresslist[index] = {
        ...clientEditAddresslist[index],
        address1: state.client.addresses[index].address1,
        address2: state.client.addresses[index].address2,
        city: state.client.addresses[index].city,
        state: state.client.addresses[index].state,
        zip: state.client.addresses[index].zip,
      };
      addressLine1 = state.client.addresses[index].address1;
    }

    index === 0
      ? (address11.current.value = addressLine1)
      : index === 1
      ? (address12.current.value = addressLine1)
      : (address13.current.value = addressLine1);

    setState((state) => ({
      ...state,
      addressRepeat: repeatArray,
      clientEdit: {
        ...state.clientEdit,
        addresses: clientEditAddresslist,
      },
    }));
  };
  const address11 = useRef(null);
  const address12 = useRef(null);
  const address13 = useRef(null);
  const [map1, setMap1] = React.useState(null);
  const [map2, setMap2] = React.useState(null);
  const [map3, setMap3] = React.useState(null);

  const onLoad1 = React.useCallback(function callback(map) {
    setMap1(map);
  }, []);
  const onLoad2 = React.useCallback(function callback(map) {
    setMap2(map);
  }, []);
  const onLoad3 = React.useCallback(function callback(map) {
    setMap3(map);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      address11.current.value = state.client.addresses[0].address1;
      address12.current.value = state.client.addresses[1].address1;
      address13.current.value = state.client.addresses[2].address1;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  const onPlaceChanged = (index) => {
    const mapObject = index === 0 ? map1 : index === 1 ? map2 : map3;
    if (mapObject.autocomplete !== null && mapObject.getPlace()) {
      const place = mapObject.getPlace();

      if (place.name === "") {
        let address1 = "";
        const clientEditAddresslist = [...state.clientEdit.addresses];
        clientEditAddresslist[index] = {
          ...clientEditAddresslist[index],
          address1: "",
          address2: "",
          zip: "",
          city: "",
          state: "",
        };
        const allowEditList = [...state.allowEditAdresses];
        allowEditList[index] = {
          ...allowEditList[index],
          zip: false,
          city: false,
          state: false,
        };
        index === 0
          ? (address11.current.value = address1)
          : index === 1
          ? (address12.current.value = address1)
          : (address13.current.value = address1);

        if (index === 0) {
          if (state.addressRepeat[1]) {
            clientEditAddresslist[1] = {
              ...clientEditAddresslist[1],
              address1: "",
              address2: "",
              zip: "",
              city: "",
              state: "",
            };
            allowEditList[1] = {
              ...allowEditList[1],
              zip: false,
              city: false,
              state: false,
            };
            address12.current.value = address1;
          }
          if (state.addressRepeat[2]) {
            clientEditAddresslist[2] = {
              ...clientEditAddresslist[2],
              address1: "",
              address2: "",
              zip: "",
              city: "",
              state: "",
            };
            allowEditList[2] = {
              ...allowEditList[2],
              zip: false,
              city: false,
              state: false,
            };
            address13.current.value = address1;
          }
        }

        setState((state) => ({
          ...state,
          clientEdit: {
            ...state.clientEdit,
            addresses: clientEditAddresslist,
          },
          allowEditAdresses: allowEditList,
        }));
        return;
      }

      let address1 = "";
      let zip = "";
      let city = "";
      let stateAdress = "";

      for (const component of place.address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case "street_number":
            address1 = `${component.long_name} ${address1}`;
            break;

          case "route":
            address1 += component.short_name;
            break;

          case "postal_code":
            zip = `${component.long_name}${zip}`;
            break;

          case "postal_code_suffix":
            zip = `${zip}-${component.long_name}`;
            break;

          case "locality":
            city = component.long_name;
            break;

          case "administrative_area_level_1":
            stateAdress = component.short_name;
            break;
          default:
            break;
        }
      }
      const clientEditAddresslist = [...state.clientEdit.addresses];
      clientEditAddresslist[index] = {
        ...clientEditAddresslist[index],
        address1,
        address2: "",
        zip,
        city,
        state: stateAdress,
      };
      const allowEditList = [...state.allowEditAdresses];
      allowEditList[index] = {
        ...allowEditList[index],
        zip: zip === "",
        city: city === "",
        state: state === "",
      };
      if (index === 0) {
        if (state.addressRepeat[1]) {
          clientEditAddresslist[1] = {
            ...clientEditAddresslist[1],
            address1,
            address2: "",
            zip,
            city,
            state: stateAdress,
          };
          allowEditList[1] = {
            ...allowEditList[1],
            zip: false,
            city: false,
            state: false,
          };
          address12.current.value = address1;
        }
        if (state.addressRepeat[2]) {
          clientEditAddresslist[2] = {
            ...clientEditAddresslist[2],
            address1,
            address2: "",
            zip,
            city,
            state: stateAdress,
          };
          allowEditList[2] = {
            ...allowEditList[2],
            zip: false,
            city: false,
            state: false,
          };
          address13.current.value = address1;
        }
      }

      index === 0
        ? (address11.current.value = address1)
        : index === 1
        ? (address12.current.value = address1)
        : (address13.current.value = address1);
      setState((state) => ({
        ...state,
        clientEdit: {
          ...state.clientEdit,
          addresses: clientEditAddresslist,
        },
        allowEditAdresses: allowEditList,
      }));
    }
  };

  return (
    <>
      {/* start: General section */}
      <Paper elevation={4} sx={{ mt: 3, px: 3, py: 2 }}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0.5}
        >
          <AccountBoxIcon sx={{ width: 25, height: 25 }} />
          <Typography
            variant="h5"
            component="h3"
            sx={{ fontWeight: "regular" }}
          >
            General information
          </Typography>
        </Stack>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
          sx={{
            mx: { xs: 1, md: 2, lg: 3 },
            mt: 3,
            mb: 1,
          }}
        >
          <Stack
            direction={{ xs: "column", lg: "row" }}
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            sx={{ width: 1 }}
          >
            <TextField
              size="small"
              required
              value={state.clientEdit.firstName}
              onChange={(event) => {
                handleGeneralChange(event, "firstName");
              }}
              sx={{ width: { xs: 1, lg: 0.5 } }}
              label="First Name"
              className="item-subtitle"
              error={state.errors.firstName !== ""}
              helperText={state.errors.firstName ?? "Input Req."}
            />
            <TextField
              size="small"
              required
              value={state.clientEdit.lastName}
              onChange={(event) => {
                handleGeneralChange(event, "lastName");
              }}
              sx={{ width: { xs: 1, lg: 0.5 } }}
              label="Last Name"
              className="item-subtitle"
              error={state.errors.lastName !== ""}
              helperText={state.errors.lastName ?? "Input Req."}
            />
          </Stack>
          <Stack
            direction={{ xs: "column", lg: "row" }}
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            sx={{ width: 1 }}
          >
            <TextField
              size="small"
              value={state.clientEdit.email}
              required
              onChange={(event) => {
                handleGeneralChange(event, "email");
              }}
              label="Email"
              sx={{ width: { xs: 1, lg: 0.5 } }}
              className="item-subtitle"
              error={state.errors.email !== ""}
              helperText={state.errors.email ?? "Input Req."}
            />
            <MuiTelInput
              size="small"
              value={state.clientEdit.phone}
              required
              sx={{ width: { xs: 1, lg: 0.5 } }}
              label="Phone"
              className="item-subtitle"
              error={state.errors.phone !== ""}
              helperText={state.errors.phone ?? "Input Req."}
              onChange={handleGeneralPhoneChange}
              defaultCountry="US"
              forceCallingCode
              onlyCountries={acceptedPhoneCountries}
            />
          </Stack>
          <Stack
            direction={{ xs: "column", lg: "row" }}
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            sx={{ width: 1 }}
          >
            <TextField
              size="small"
              value={state.clientEdit.company}
              onChange={(event) => {
                handleGeneralChange(event, "company");
              }}
              label="Company"
              sx={{ width: { xs: 1, lg: 0.5 } }}
              className="item-subtitle"
            />
            <TextField
              size="small"
              value={state.clientEdit.website}
              onChange={(event) => {
                handleGeneralChange(event, "website");
              }}
              sx={{ width: { xs: 1, lg: 0.5 } }}
              label="Website"
              className="item-subtitle"
            />
          </Stack>
        </Stack>
      </Paper>
      {/* end: General section */}

      {/* start: Phone section */}
      <Paper elevation={4} sx={{ mt: 3, px: 3, py: 2 }}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0.5}
        >
          <PhoneIcon sx={{ width: 25, height: 25 }} />
          <Typography
            variant="h5"
            component="h3"
            sx={{ fontWeight: "regular" }}
          >
            Phone information
          </Typography>
        </Stack>

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={5}
          sx={{
            mx: { xs: 1, md: 2, lg: 3 },
            mt: 3,
            mb: 1,
          }}
        >
          {state.clientEdit?.phoneList?.map((phoneItem, index) => {
            return (
              <ItemEdit
                key={"phone-" + index}
                types={phoneTypes}
                label="phone"
                item={phoneItem}
                index={index}
                onChange={handlePhoneChange}
                handlePhoneValueChange={handlePhoneValueChange}
                onRemove={removePhoneItem}
                stateItem="phoneList"
                errors={state.errors}
              />
            );
          })}
          <Button color="primaryTk" variant="contained" onClick={addPhoneItem}>
            Add
          </Button>
        </Stack>
      </Paper>
      {/* end: Phone section */}

      {/* start: Email section */}
      <Paper elevation={4} sx={{ mt: 3, px: 3, py: 2 }}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0.5}
        >
          <MailIcon sx={{ width: 25, height: 25 }} />
          <Typography
            variant="h5"
            component="h3"
            sx={{ fontWeight: "regular" }}
          >
            Email information
          </Typography>
        </Stack>

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={{ xs: 5, md: 3 }}
          sx={{
            mx: { xs: 1, md: 2, lg: 3 },
            mt: 3,
            mb: 1,
          }}
        >
          {state.clientEdit?.emailList?.map((emailItem, index) => {
            return (
              <ItemEdit
                key={"email-" + index}
                types={emailTypes}
                label="email"
                item={emailItem}
                index={index}
                onRemove={removeEmailItem}
                onChange={handleEmailChange}
                stateItem="emailList"
                errors={state.errors}
              />
            );
          })}
          <Button color="primaryTk" variant="contained" onClick={addEmailItem}>
            Add
          </Button>
        </Stack>
      </Paper>
      {/* end: Email section */}

      {/* start: Media section */}
      <Paper elevation={4} sx={{ mt: 3, px: 3, py: 2 }}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0.5}
        >
          <PublicIcon sx={{ width: 25, height: 25 }} />
          <Typography
            variant="h5"
            component="h3"
            sx={{ fontWeight: "regular" }}
          >
            Social Media information
          </Typography>
        </Stack>

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={{ xs: 5, md: 4, lg: 2 }}
          sx={{
            mx: { xs: 1, md: 2, lg: 3 },
            mt: 3,
            mb: 1,
          }}
        >
          {state.clientEdit?.mediaList?.map((mediaItem, index) => {
            return (
              <ItemEdit
                key={"media-" + index}
                types={state.mediaTypes}
                label="social media"
                item={mediaItem}
                index={index}
                onRemove={removeMediaItem}
                onChange={handleSocialChange}
                stateItem="mediaList"
                errors={state.errors}
              />
            );
          })}
          <Button color="primaryTk" variant="contained" onClick={addMediaItem}>
            Add
          </Button>
        </Stack>
      </Paper>
      {/* end: Media section */}

      {/* start: Address section */}
      <Paper elevation={4} sx={{ mt: 3, px: 3, py: 2 }}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0.5}
        >
          <RoomIcon sx={{ width: 25, height: 25 }} />
          <Typography
            variant="h5"
            component="h3"
            sx={{ fontWeight: "regular" }}
          >
            Address information
          </Typography>
        </Stack>

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={4}
          sx={{
            mx: { xs: 1, md: 2, lg: 3 },
            mt: 3,
            mb: 1,
          }}
        >
          {state.clientEdit?.addresses?.map((addressItem, index) => {
            return (
              <AddressItem
                key={"address-" + index}
                item={addressItem}
                index={index}
                stateItem="addresses"
                errors={state.errors}
                onChange={handleAddressChange}
                isLoaded={isLoaded}
                onLoad={index === 0 ? onLoad1 : index === 1 ? onLoad2 : onLoad3}
                address1Ref={
                  index === 0 ? address11 : index === 1 ? address12 : address13
                }
                onPlaceChanged={onPlaceChanged}
                sameAsBusiness={sameAsBusiness}
                repeatValue={state.addressRepeat[index]}
                allowEditField={state.allowEditAdresses[index]}
              />
            );
          })}
        </Stack>
      </Paper>
      {/* end: Address section */}
    </>
  );
};

export default React.memo(FormClientInfo);
