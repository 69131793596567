export const environments = {
  localhost: {
    baseUrl: 'http://localhost:3000/dev',
  },
  development: {
    // baseUrl: 'https://api.tapkards.com/dev',
    baseUrl: 'http://localhost:3000/dev',
  },
  production: {
    baseUrl: 'https://api.tapkards.com',
  },
};
