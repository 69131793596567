import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { CircularProgress, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import { singleNameRegex } from "../../../config/constants";
import { createAllPets } from "../../../config/api";
import { AuthContext } from "../../../App";
import AddIcon from "@mui/icons-material/Add";

const AddPetForm = ({ openNewPet, setOpenNewPet, state, setState, refreshSubscriptions }) => {
  const [petsForm, setPetsForm] = React.useState([""]);
  const [errors, setErrors] = React.useState([""]);
  const [requesting, setRequesting] = React.useState(false);

  const addPetItem = () => {
    const petsArray = [...petsForm];
    const errorsArray = [...errors];
    petsArray.push("");
    errorsArray.push("");
    setErrors(errorsArray);
    setPetsForm(petsArray);
  };

  const removePetItem = (index) => {
    const petsArray = [...petsForm];
    const errorsArray = [...errors];
    petsArray.splice(index, 1);
    errorsArray.splice(index, 1);
    setErrors(errorsArray);
    setPetsForm(petsArray);
  };

  const changePetItem = (event, index) => {
    const value = event.target.value;
    const petsArray = [...petsForm];
    petsArray[index] = value;
    setPetsForm(petsArray);
  };

  const validateStepForm = () => {
    const errorsArray = structuredClone(errors);
    let validForm = true;

    for (let index = 0; index < petsForm.length; index++) {
      errorsArray[index] = "";
      if (!singleNameRegex.test(petsForm[index])) {
        errorsArray[index] = "Invalid field";
        validForm = false;
      }
      if (petsForm[index].length === 0) {
        errorsArray[index] = "Required field";
        validForm = false;
      }
    }

    setErrors(errorsArray);
    return validForm;
  };

  const authContext = React.useContext(AuthContext);

  const token = authContext.auth.authItem.accessToken;
  const id = authContext.auth.authItem.decodedJwt.sub;
  const submitAdditionalPet = async () => {
    const valid = validateStepForm();
    if (!valid) {
      return;
    }
    setRequesting(true);
    const pets = petsForm.map((pet) => {
      return { name: pet };
    });
    const responses = await createAllPets(pets, token, id);
    const newPets = [...state.pets];

    responses.forEach((response) => {
      if (response.success) {
        const pet = {
          petId: response.responseData.petId,
          name: response.responseData.name,
          image: response.responseData.image,
          unpaid: true,
        };
        newPets.push(pet);
        refreshSubscriptions();
      }
    });
    const failed = responses.some((response) => response.success === false);
    if (!failed) {
      setState((state) => ({
        ...state,
        requesting: false,
        pets: newPets,
        snackMessage: {
          display: true,
          message: "Your information was successfully updated",
          type: "success",
        },
      }));
    } else {
      setState((state) => ({
        ...state,
        requesting: false,
        pets: newPets,
        snackMessage: {
          display: true,
          message:
            "There was a problem creating the pets info, please try again",
          type: "error",
        },
      }));
    }
    setRequesting(false);
    setOpenNewPet(false);
  };

  return (
    <Dialog
      open={openNewPet}
      maxWidth="xs"
      fullWidth
      keepMounted
      onClose={() => setOpenNewPet(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          variant="h5"
          component="h3"
          color="#33428C"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            mt: 1,
            mb: 3,
          }}
        >
          Add your pets
        </Typography>
        {petsForm.map((pet, index) => {
          return (
            <PetItem
              label="Pets name"
              value={pet}
              onRemove={removePetItem}
              index={index}
              onChange={changePetItem}
              errors={errors}
              addPetItem={addPetItem}
            />
          );
        })}
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
        <Button
          variant="outlined"
          color="primaryTk"
          sx={{ borderRadius: "30px" }}
          onClick={() => setOpenNewPet(false)}
        >
          Close
        </Button>
        <Button
          variant="contained"
          color="primaryTk"
          sx={{ ml: 1, borderRadius: "30px" }}
          onClick={submitAdditionalPet}
        >
          {requesting && <CircularProgress color="white" size="1.4rem" />}
          {!requesting && <>Submit</>}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPetForm;

const PetItem = ({
  label,
  value,
  onRemove,
  index,
  onChange,
  errors,
  addPetItem,
}) => {
  return (
    <Grid container alignItems="center" sx={{ mb: 2 }}>
      <Grid item xs={12}>
        <Stack
          direction={{ xs: "row", lg: "row" }}
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          sx={{ width: 1 }}
        >
          <TextField
            size="small"
            required
            value={value}
            onChange={(event) => {
              onChange(event, index);
            }}
            sx={{ width: { xs: 0.7, lg: 1 } }}
            label={label}
            className="item-subtitle"
            error={errors[index] !== ""}
            helperText={errors[index] ?? "Input Req."}
          />
          {index >= 1 && (
            <Button
              aria-label="delete"
              color="error"
              variant="outlined"
              onClick={() => onRemove(index)}
              sx={{}}
            >
              <DeleteIcon />
            </Button>
          )}
          {index === 0 && (
            <Button
              color="primaryTk"
              onClick={addPetItem}
              variant="contained"
              sx={{ px: 0 }}
            >
              <AddIcon />
            </Button>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};
