import { baseUrl } from './constants';

const requestConfig = {
  method: 'GET',
  headers: {
    'Content-type': 'application/json; charset=UTF-8',
  },
};

export const fetchImage = async (img_name, type) => {
  let imgageBase64 = null;
  try {
    const imgResponse = await fetch(
      `${baseUrl}/${type}/images/${img_name}`,
      requestConfig
    );
    if (imgResponse.ok) {
      const imgData = await imgResponse.blob();
      const objectURL = await URL.createObjectURL(imgData);
      imgageBase64 = objectURL;
      return {
        success: true,
        imgageBase64,
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      error,
    };
  }
  return imgageBase64;
};

export const fetchProfile = async (token, id) => {
  try {
    const profileConfig = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: 'Bearer ' + token,
      },
    };
    const response = await fetch(`${baseUrl}/profiles/${id}`, profileConfig);
    const responseData = await response.json();
    if (response.ok) {
      const client = responseData.client;
      if (client.phoneList === null) {
        client.phoneList = [];
      }
      if (client.emailList === null) {
        client.emailList = [];
      }
      if (client.mediaList === null) {
        client.mediaList = [];
      }
      const mediaTypes = [];
      const mediaKeys = Object.keys(responseData.mediaTypes);
      mediaKeys.forEach((key) => {
        const mediaObject = {
          label: responseData.mediaTypes[key].type,
          value: responseData.mediaTypes[key].type,
        };
        mediaTypes.push(mediaObject);
      });
      if (client.image) {
        client.img64 = await fetchImage(client.image, 'clients');
      }

      const initialAddresses = [...client.addresses];
      const businessIndex = initialAddresses.findIndex(
        (element) => element.type === 'business'
      );
      const shippingIndex = initialAddresses.findIndex(
        (element) => element.type === 'shipping'
      );
      const billingIndex = initialAddresses.findIndex(
        (element) => element.type === 'billing'
      );
      const addressesArray = [
        initialAddresses[businessIndex],
        initialAddresses[shippingIndex],
        initialAddresses[billingIndex],
      ];

      client.addresses = addressesArray;

      let pets = [];
      if (responseData.pets.length > 0) {
        pets = await Promise.all(
          responseData.pets.map(async (pet) => {
            const mutablePet = { ...pet };
            if (mutablePet.image) {
              mutablePet.img64 = await fetchImage(mutablePet.image, 'pets');
            }
            return {
              ...mutablePet,
            };
          })
        );
      }
      const currentSubscription = [
        ...(responseData?.subscriptions[0]?.detail ?? []),
      ];
      pets.forEach((pet) => {
        const founded = currentSubscription.find(
          (detail) => detail.petId === pet.petId
        );
        pet.unpaid = founded !== undefined && founded?.transactionId === null;
      });

      return {
        success: true,
        data: responseData,
        pets,
        client,
        mediaTypes,
      };
    } else {
      return {
        success: false,
        expired: response.status === 403,
        message: responseData?.message ?? 'Could not get the profile',
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: JSON.stringify(error.message),
    };
  }
};

export const refreshSubscription = async (token, id) => {
  try {
    const profileConfig = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: 'Bearer ' + token,
      },
    };
    const response = await fetch(
      `${baseUrl}/subscriptions/clients/${id}`,
      profileConfig
    );
    const responseData = await response.json();
    if (response.ok) {
      return {
        success: true,
        subscriptions: responseData.subscriptions,
      };
    } else {
      return {
        success: false,
        expired: response.status === 403,
        message: responseData?.message ?? 'Could not get the profile',
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: JSON.stringify(error.message),
    };
  }
};

export const fetchPrices = async () => {
  try {
    const response = await fetch(`${baseUrl}/landing/settings`, requestConfig);
    const responseData = await response.json();
    if (response.ok) {
      return {
        success: true,
        ...responseData,
      };
    } else {
      return {
        success: false,
        message: responseData?.message ?? 'Could not get the landing settings',
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: JSON.stringify(error.message),
    };
  }
};

export const loginRequestOtp = async (phone) => {
  try {
    const payload = { phone };

    const otpRequestConfig = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(payload),
    };

    const response = await fetch(`${baseUrl}/clients/auth`, otpRequestConfig);
    const responseData = await response.json();
    if (response.ok) {
      return {
        success: true,
        ...responseData,
      };
    } else {
      return {
        success: false,
        message: responseData?.message ?? 'Could not generate your code',
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: JSON.stringify(error.message),
    };
  }
};

export const loginValidateOtp = async (clientId, code) => {
  try {
    const payload = {
      clientId,
      code,
    };

    const otpRequestConfig = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(payload),
    };

    const response = await fetch(`${baseUrl}/clients/login`, otpRequestConfig);
    const responseData = await response.json();
    if (response.ok) {
      return {
        success: true,
        ...responseData,
      };
    } else {
      return {
        success: false,
        message: responseData?.message ?? 'Could not validate your code',
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: JSON.stringify(error.message),
    };
  }
};

export const landingSubscription = async (payload) => {
  try {
    const subscriptionConfig = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(payload),
    };

    const response = await fetch(
      `${baseUrl}/landing/subscriptions`,
      subscriptionConfig
    );
    const responseData = await response.json();
    if (response.ok) {
      return {
        success: true,
        responseData,
      };
    } else {
      return {
        success: false,
        message: responseData?.message ?? 'Could not complete the subscription',
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: JSON.stringify(error.message),
    };
  }
};

export const updatePet = async (payload, token, id) => {
  const updateConfig = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(payload),
  };

  try {
    const response = await fetch(`${baseUrl}/pets/${id}`, updateConfig);
    const responseData = await response.json();
    if (response.ok) {
      return {
        success: true,
        responseData,
      };
    } else {
      return {
        success: false,
        expired: response.status === 403,
        message: responseData?.message ?? 'Could not update pet',
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      error,
    };
  }
};

export const updateAllPets = async (pets, token) => {
  try {
    const petsUpdates = await Promise.all(
      pets.map(async (pet) => {
        const mutablePet = { ...pet };
        const payload = { name: mutablePet.name };
        const update = await updatePet(payload, token, pet.petId);
        return update;
      })
    );
    return petsUpdates;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: JSON.stringify(error.message),
    };
  }
};

export const updateImage = async (token, id, file, type) => {
  try {
    const data = new FormData();
    const payload = {
      ...(type === 'client' && { clientId: id }),
      ...(type === 'pet' && { petId: id }),
      image: file,
    };
    const keys = Object.keys(payload);
    keys.forEach((key, index) => {
      data.append(`${key}`, payload[key]);
    });

    const uploadImageConfig = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      method: 'PATCH',
      body: data,
    };
    const url =
      type === 'client'
        ? `${baseUrl}/clients/uploadImage`
        : `${baseUrl}/pets/uploadImage`;
    const response = await fetch(url, uploadImageConfig);
    const responseData = await response.json();
    if (response.ok) {
      if (responseData.image) {
        const imageType = type + 's';
        responseData.img64 = await fetchImage(responseData.image, imageType);
        return {
          success: true,
          responseData,
        };
      } else {
        return {
          success: false,
          expired: response.status === 403,
          message: 'Image updated, but could not download the new image',
        };
      }
    } else {
      return {
        success: false,
        expired: response.status === 403,
        message:
          responseData?.message ??
          'Could not update the profile image, please try again',
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: JSON.stringify(error.message),
    };
  }
};

export const createPet = async (payload, token) => {
  const createConfig = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(payload),
  };

  try {
    const response = await fetch(`${baseUrl}/pets/`, createConfig);
    const responseData = await response.json();
    if (response.ok) {
      return {
        success: true,
        responseData,
      };
    } else {
      return {
        success: false,
        expired: response.status === 403,
        message: responseData?.message ?? 'Could not create pet',
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      error,
    };
  }
};

export const createAllPets = async (pets, token, clientId) => {
  try {
    const petsUpdates = await Promise.all(
      pets.map(async (pet) => {
        const mutablePet = { ...pet };
        const payload = { name: mutablePet.name, clientId };
        const create = await createPet(payload, token);
        return create;
      })
    );
    return petsUpdates;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: JSON.stringify(error.message),
    };
  }
};

export const paySubscription = async (payload, token) => {
  try {
    const createConfig = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(payload),
    };

    const response = await fetch(
      `${baseUrl}/subscriptions/payment`,
      createConfig
    );
    const responseData = await response.json();
    if (response.ok) {
      return {
        success: true,
        responseData,
      };
    } else {
      return {
        success: false,
        expired: response.status === 403,
        message: responseData?.message ?? 'Could not make the payment',
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      error,
    };
  }
};

export const updateAddress = async (payload, token, addressId) => {
  const updateConfig = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(payload),
  };

  try {
    const response = await fetch(
      `${baseUrl}/addresses/${addressId}`,
      updateConfig
    );
    const responseData = await response.json();
    if (response.ok) {
      return {
        success: true,
        responseData,
      };
    } else {
      return {
        success: false,
        expired: response.status === 403,
        message: responseData?.message ?? 'Could not update the address',
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      error,
    };
  }
};

export const deletePet = async (id, token) => {
  const deleteConfig = {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: 'Bearer ' + token,
    },
  };

  try {
    const response = await fetch(`${baseUrl}/pets/${id}`, deleteConfig);
    const responseData = await response.json();
    if (response.ok) {
      return {
        success: true,
        responseData,
      };
    } else {
      return {
        success: false,
        expired: response.status === 403,
        message: responseData?.message ?? 'Could not delete pet',
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      error,
    };
  }
};

export const additionalBusinessAdd = async (subscriptionId, token) => {
  const createConfig = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: 'Bearer ' + token,
    },
  };

  try {
    const response = await fetch(
      `${baseUrl}/subscriptions/${subscriptionId}/clients`,
      createConfig
    );
    const responseData = await response.json();
    if (response.ok) {
      return {
        success: true,
        responseData,
      };
    } else {
      return {
        success: false,
        expired: response.status === 403,
        message: responseData?.message ?? 'Could not create pet',
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      error,
    };
  }
};

export const additionalBusinessDelete = async (subscriptionId, token) => {
  const deleteConfig = {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: 'Bearer ' + token,
    },
  };

  try {
    const response = await fetch(
      `${baseUrl}/subscriptions/${subscriptionId}/clients`,
      deleteConfig
    );
    const responseData = await response.json();
    if (response.ok) {
      return {
        success: true,
        responseData,
      };
    } else {
      return {
        success: false,
        expired: response.status === 403,
        message: responseData?.message ?? 'Could not create pet',
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      error,
    };
  }
};

export const cancelTransaction = async (transactionId, token) => {
  const cancelConfig = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: 'Bearer ' + token,
    },
  };

  try {
    const response = await fetch(
      `${baseUrl}/transactions/${transactionId}/void`,
      cancelConfig
    );
    if (response.ok) {
      return {
        success: true,
      };
    } else {
      const responseData = await response.json();
      return {
        success: false,
        expired: response.status === 403,
        message: responseData?.message ?? 'Could not void your transaction',
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      error,
    };
  }
};

export const cancelSubscription = async (subscriptionId, token) => {
  const cancelConfig = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: 'Bearer ' + token,
    },
  };

  try {
    const response = await fetch(
      `${baseUrl}/subscriptions/${subscriptionId}/void`,
      cancelConfig
    );
    if (response.ok) {
      return {
        success: true,
      };
    } else {
      const responseData = await response.json();
      return {
        success: false,
        expired: response.status === 403,
        message: responseData?.message ?? 'Could not cancel your subscription',
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      error,
    };
  }
};

export const renewSubscription = async (subscriptionId, token) => {
  const renewSubscription = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: 'Bearer ' + token,
    },
  };

  try {
    const response = await fetch(
      `${baseUrl}/subscriptions/${subscriptionId}/renew`,
      renewSubscription
    );
    const responseData = await response.json();
    if (response.ok) {
      return {
        success: true,
        responseData,
      };
    } else {
      return {
        success: false,
        expired: response.status === 403,
        message: responseData?.message ?? 'Could not renew your subscription',
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      error,
    };
  }
};

export const rebuySubscription = async (subscriptionId, token) => {
  const rebuySubscription = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: 'Bearer ' + token,
    },
  };

  try {
    const response = await fetch(
      `${baseUrl}/subscriptions/${subscriptionId}/rebuy`,
      rebuySubscription
    );
    const responseData = await response.json();
    if (response.ok) {
      return {
        success: true,
        responseData,
      };
    } else {
      return {
        success: false,
        expired: response.status === 403,
        message:
          responseData?.message ?? 'Could not buy again your subscription',
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      error,
    };
  }
};
