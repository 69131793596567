import React from "react";
import MailIcon from "@mui/icons-material/Mail";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import HomeIcon from "@mui/icons-material/Home";
import WorkIcon from "@mui/icons-material/Work";
import Typography from "@mui/material/Typography";
import { Box, Stack, Link } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";

const EmailSection = ({ emailList, loading }) => {
  const iconSelect = (type) => {
    switch (type) {
      case "work":
        return <WorkIcon className="secondary-icon-color" />;
      case "home":
        return <HomeIcon className="secondary-icon-color" />;
      default:
        return <MoreHorizIcon className="secondary-icon-color" />;
    }
  };
  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <MailIcon className="main-icon-color" />
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={loading ? 0 : 1}
          sx={{ pt: 0.5 }}
        >
          {!loading && (
            <>
              {emailList.map((email) => {
                return (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={0.5}
                    sx={{ width: 1 }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={0.5}
                    >
                      {iconSelect(email.type)}
                      <Typography
                        variant="body1"
                        align="left"
                        component="h3"
                        noWrap
                      >
                        <Link
                          href={`mailto:${email.value}`}
                          underline="hover"
                          color="inherit"
                        >
                          {email.value}
                        </Link>
                      </Typography>
                    </Stack>
                  </Stack>
                );
              })}
            </>
          )}
          {loading && (
            <>
              <Skeleton width={200} height={35} />
              <Skeleton width={200} height={35} />
              <Skeleton width={200} height={35} />
            </>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

EmailSection.defaultProps = {
  emailList: [],
  loading: true,
};

EmailSection.propTypes = {
  emailList: PropTypes.array,
  loading: PropTypes.bool,
};

export default EmailSection;
