import React from "react";
import RoomIcon from "@mui/icons-material/Room";
import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";

const AddresssSection = ({ address1, address2, city, state, zip, loading }) => {
  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <RoomIcon className="main-icon-color" />
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={0}
          sx={{ pt: 0.5 }}
        >
          <Typography variant="body1" align="left" component="h3">
            { !loading ? <> {address1} {address2} </> : <Skeleton width={200} height={35}/> }
          </Typography>
          <Typography variant="body1" align="left" component="h3">
            { !loading ? <> {city}, {state}, {zip} </> : <Skeleton width={200} height={35}/> }
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

AddresssSection.defaultProps = {
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  loading: true,
};

AddresssSection.propTypes = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  loading: PropTypes.bool,
};

export default AddresssSection;
