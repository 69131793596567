import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function BusinessPetCardIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <g id="Icon">
        <path d="M7.36,10.05A1.32,1.32,0,1,0,6.27,8.52,1.33,1.33,0,0,0,7.36,10.05Z"></path>
        <path d="M6.48,13a1.4,1.4,0,0,1,1.25-1.53l.55,0a2.23,2.23,0,0,0-1-.48,2.26,2.26,0,0,0-2.59,1.82L4.44,14l2.18.38Z"></path>
        <path d="M6.72,15.34l-2.88-.49a.51.51,0,0,1-.41-.53l.29-1.75a3.17,3.17,0,0,1,2.13-2.43,2.2,2.2,0,0,1-.49-1.77,2.25,2.25,0,0,1,4.44.75,2.15,2.15,0,0,1-1,1.52,2.89,2.89,0,0,1,.63.63L22.85,9.88l.38-2.27A1.35,1.35,0,0,0,22.08,6L3.69,2.88A1.4,1.4,0,0,0,2.07,4L0,16.09A1.41,1.41,0,0,0,1.17,17.7l5.9,1Z"></path>
        <path d="M12.65,18.5a1.4,1.4,0,0,1-.7-.12,2.29,2.29,0,0,1,.14,1.11.35.35,0,0,1,.05-.08c.16-.25.28-.45.38-.57a1.59,1.59,0,0,1,1-.55h0a1.48,1.48,0,0,1,.42,0A1.87,1.87,0,0,1,15,19l.06.07.41.47a2.28,2.28,0,0,1,0-1.4,1.73,1.73,0,0,1-.7.26.83.83,0,0,1-.23,0,1.31,1.31,0,0,1-1-.78,1.39,1.39,0,0,1-.06.19,1.13,1.13,0,0,1-.45.53A1,1,0,0,1,12.65,18.5Z"></path>
        <path d="M14.7,17.7c.15,0,.65-.15.74-.5a2.6,2.6,0,0,0-.27-1.52.52.52,0,0,0-.59-.28c-.23,0-.47.39-.46,1.26S14.5,17.66,14.7,17.7Z"></path>
        <path d="M12.72,17.73a.36.36,0,0,0,.15-.2,2.16,2.16,0,0,0,0-1.62.44.44,0,0,0-.06-.1h0c-.05-.08-.14-.15-.41-.18h0a.32.32,0,0,0-.16.1,2,2,0,0,0-.43,1.41.63.63,0,0,0,.32.52A.73.73,0,0,0,12.72,17.73Z"></path>
        <path d="M10.7,18.06h0a.58.58,0,0,0-.19,0h0c-.15,0-.24.18-.28.24a1.44,1.44,0,0,0,0,1.25.49.49,0,0,0,.39.32.66.66,0,0,0,.5-.1,1,1,0,0,0,.25-.27,1.32,1.32,0,0,0-.08-.86A.87.87,0,0,0,10.7,18.06Z"></path>
        <path d="M27.18,10.26,8.63,12.18a1.4,1.4,0,0,0-1.25,1.53L8.64,25.9a1.4,1.4,0,0,0,1.54,1.23l18.56-1.92A1.4,1.4,0,0,0,30,23.68L28.73,11.5A1.37,1.37,0,0,0,27.18,10.26ZM18,19.64a1.11,1.11,0,0,1-.66.56,3.22,3.22,0,0,1-.65.16,1.37,1.37,0,0,1-.55-.06l.23.27a2.26,2.26,0,0,1,.59.94,1.76,1.76,0,0,1-.06,1.17,1.36,1.36,0,0,1-.86.73l-.2,0a2.06,2.06,0,0,1-.76-.09l-.41-.11a2.26,2.26,0,0,0-.83-.16,5.4,5.4,0,0,0-1,.24l-.14.05a1.43,1.43,0,0,1-1.14.09,1.66,1.66,0,0,1-.87-1.41,1.89,1.89,0,0,1,.41-1.19l.2-.27.18-.26a1.31,1.31,0,0,1-.64.24,1.32,1.32,0,0,1-1.35-.76A2.2,2.2,0,0,1,9.58,18a1.11,1.11,0,0,1,.86-.65,1.42,1.42,0,0,1,.45,0l.19.08a1,1,0,0,1,0-.24,2.77,2.77,0,0,1,.65-2,1,1,0,0,1,.77-.3,1.22,1.22,0,0,1,.93.48,1,1,0,0,1,.14.24,1.2,1.2,0,0,1,.93-.94,1.25,1.25,0,0,1,1.31.58,3.38,3.38,0,0,1,.4,2,1.18,1.18,0,0,1,.66-.25,1.06,1.06,0,0,1,.47.08,1.2,1.2,0,0,1,.68.87,7,7,0,0,1,.13.87A1.66,1.66,0,0,1,18,19.64Zm8.53.78-7.14.74a.45.45,0,1,1-.09-.9l7.14-.74a.46.46,0,0,1,.5.4A.47.47,0,0,1,26.54,20.42Zm-.28-2.67-7.14.75a.48.48,0,0,1-.5-.41A.46.46,0,0,1,19,17.6l7.14-.75a.49.49,0,0,1,.5.41A.45.45,0,0,1,26.26,17.75Z"></path>
        <path d="M17,17.74a.34.34,0,0,0-.14,0h0a.73.73,0,0,0-.41.25,1.41,1.41,0,0,0-.31.91c0,.5.15.7.2.72s.35.06.75-.09a.46.46,0,0,0,.26-.17,1.3,1.3,0,0,0,0-.49,5.79,5.79,0,0,0-.11-.76A.5.5,0,0,0,17,17.74Z"></path>
        <path d="M15.84,21.06l-.53-.61-.75-.86-.07-.07c-.25-.29-.43-.47-.68-.5h-.22a.79.79,0,0,0-.5.28,3.58,3.58,0,0,0-.33.5c-.21.33-.5.79-.84,1.24a1.44,1.44,0,0,0-.18.25,1.15,1.15,0,0,0-.28.74.93.93,0,0,0,.45.78c.13.06.35,0,.56-.11l.16-.06a6.4,6.4,0,0,1,1.14-.27,2.66,2.66,0,0,1,1.13.18l.27.08a1.5,1.5,0,0,0,.68.08.64.64,0,0,0,.39-.33,1.08,1.08,0,0,0,0-.7A1.62,1.62,0,0,0,15.84,21.06Z"></path>
      </g>
      </svg>
    </SvgIcon>
  );
}

export default BusinessPetCardIcon;
