import React, { useState, useContext } from "react";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import LoginIcon from "@mui/icons-material/Login";
import Button from "@mui/material/Button";
import logo from "../../../image/logo.png";
import { Box, Stack, AppBar } from "@mui/material";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { AuthContext } from "../../../App";
import Fab from "@mui/material/Fab";
import Typography from "@mui/material/Typography";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const HeaderSection = ({ variant, downloadFunction }) => {
  const authContext = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const logout = () => {
    localStorage.removeItem("paymentInfo");
    const contextInitialValue = { ...authContext.initalAuthState };
    authContext.setAuth(contextInitialValue);
    window.location.assign("/login");
  };

  const fabContainerBox = {
    position: "fixed",
    bottom: 16,
    right: 16,
  };

  return (
    <AppBar className="header-bg">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ px: 1, py: 1.5 }}
      >
        <Box>
          <Link to="/">
            <img
              src={logo}
              width="82"
              height="35"
              alt="TapKards Logo"
              className=""
            />
          </Link>
        </Box>
        <Box>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            {!authContext.auth.loggedIn && (
              <>
                {variant === "business" && (
                  <Button
                    className="header-button"
                    variant="contained"
                    disableRipple
                    sx={{ color: "#000" }}
                    onClick={downloadFunction}
                  >
                    <PersonAddAlt1Icon className="header-icon-color" />
                    <Typography variant="body2" component="span" sx={{ ml: 1 }}>
                      Add to contacts
                    </Typography>
                  </Button>
                )}
              </>
            )}

            {authContext.auth.loggedIn && (
              <>
                <Button
                  className="header-button"
                  variant="contained"
                  disableRipple
                  sx={{ minWidth: 40, maxWidth: 40 }}
                  onClick={logout}
                >
                  <LogoutIcon className="header-icon-color" />
                </Button>
              </>
            )}
          </Stack>
        </Box>
      </Stack>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        sx={fabContainerBox}
        spacing={0.5}
      >
        {variant === "business" && (
          <Fab size="medium" onClick={handleClickOpen} color="primaryTk">
            <QrCode2Icon color="primaryTk.contrastText" />
          </Fab>
        )}
        <Link to="/login">
          <Fab size="medium" color="primaryTk">
            <LoginIcon color="primaryTk.contrastText" />
          </Fab>
        </Link>
      </Stack>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="dialog for displaying the QR code of the current business card"
      >
        <DialogContent>
          <QRCode value={window.location.href} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
};

HeaderSection.defaultProps = {
  variant: "",
};

HeaderSection.propTypes = {
  variant: PropTypes.string,
  downloadFunction: PropTypes.func,
};

export default HeaderSection;
