import React from "react";
import Typography from "@mui/material/Typography";
import { Stack, Paper } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import PublicIcon from "@mui/icons-material/Public";
import RoomIcon from "@mui/icons-material/Room";

const DisplayPersonalInfo = ({ state }) => {
  return (
    <>
      {/* start: Phone section */}
      {state?.client?.phoneList?.length > 0 && (
        <Paper elevation={4} sx={{ mt: 3, px: 3, py: 2 }}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0.5}
          >
            <PhoneIcon sx={{ width: 25, height: 25 }} />
            <Typography
              variant="h5"
              component="h3"
              sx={{ fontWeight: "regular" }}
            >
              Phone information
            </Typography>
          </Stack>

          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={1}
            sx={{
              mx: 3,
              mt: 2,
            }}
          >
            {state.client.phoneList.map((phoneItem, index) => {
              return (
                <Typography
                  variant="body1"
                  component="p"
                  key={"phone-" + index}
                  sx={{ fontWeight: "regular" }}
                >
                  <span className="fw-bold item-subtitle">
                    {phoneItem.type}:
                  </span>{" "}
                  {phoneItem.value}
                </Typography>
              );
            })}
          </Stack>
        </Paper>
      )}
      {/* end: Phone section */}

      {/* start: Email section */}
      {state?.client?.emailList?.length > 0 && (
        <Paper elevation={4} sx={{ mt: 3, px: 3, py: 2 }}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0.5}
          >
            <MailIcon sx={{ width: 25, height: 25 }} />
            <Typography
              variant="h5"
              component="h3"
              sx={{ fontWeight: "regular" }}
            >
              Email information
            </Typography>
          </Stack>

          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={1}
            sx={{
              mx: 3,
              mt: 2,
            }}
          >
            {state.client.emailList.map((emailItem, index) => {
              return (
                <Typography
                  variant="body1"
                  component="p"
                  key={"email-" + index}
                  sx={{ fontWeight: "regular" }}
                >
                  <span className="fw-bold item-subtitle">
                    {emailItem.type}:
                  </span>{" "}
                  {emailItem.value}
                </Typography>
              );
            })}
          </Stack>
        </Paper>
      )}
      {/* end: Email section */}

      {/* start: Media section */}
      {state?.client?.mediaList?.length > 0 && (
        <Paper elevation={4} sx={{ mt: 3, px: 3, py: 2 }}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0.5}
          >
            <PublicIcon sx={{ width: 25, height: 25 }} />
            <Typography
              variant="h5"
              component="h3"
              sx={{ fontWeight: "regular" }}
            >
              Social Media information
            </Typography>
          </Stack>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={1}
            sx={{
              mx: 3,
              mt: 2,
            }}
          >
            {state.client.mediaList.map((mediaItem, index) => {
              return (
                <Typography
                  variant="body1"
                  component="p"
                  key={"media-" + index}
                  sx={{ fontWeight: "regular" }}
                >
                  <span className="fw-bold item-subtitle">
                    {mediaItem.type}:
                  </span>{" "}
                  {mediaItem.value}
                </Typography>
              );
            })}
          </Stack>
        </Paper>
      )}
      {/* end: Media section */}

      {/* start: Address section */}
      <Paper elevation={4} sx={{ mt: 3, px: 3, py: 2 }}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0.5}
        >
          <RoomIcon sx={{ width: 25, height: 25 }} />
          <Typography
            variant="h5"
            component="h3"
            sx={{ fontWeight: "regular" }}
          >
            Address information
          </Typography>
        </Stack>

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={1}
          sx={{
            mx: 3,
            mt: 2,
          }}
        >
          {state.client.addresses.map((addressItem, index) => {
            return (
              <Typography
                variant="body1"
                component="p"
                key={"address-" + index}
                sx={{ fontWeight: "regular" }}
              >
                <span className="fw-bold item-subtitle">
                  {addressItem.type} Address:
                </span>
                {addressItem.address1}, {addressItem.address2},{" "}
                {addressItem.city}, {addressItem.state}, {addressItem.zip}
              </Typography>
            );
          })}
        </Stack>
      </Paper>
      {/* end: Address section */}
    </>
  );
};

export default DisplayPersonalInfo;
