import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { CheckoutContext } from "./Checkout";
import AddressFormItem from "./AddressFormItem";
import { MuiTelInput } from "mui-tel-input";
import { acceptedPhoneCountries } from "../../config/constants";

export default function AddressForm() {
  const checkoutContext = useContext(CheckoutContext);

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    checkoutContext.setCheckoutState({
      ...checkoutContext.checkoutState,
      client: { ...checkoutContext.checkoutState.client, [name]: value },
    });
  };

  const handleInputFocus = (event) => {
    const name = event.target.name;
    checkoutContext.setCheckoutState({
      ...checkoutContext.checkoutState,
      errors: {
        ...checkoutContext.checkoutState.errors,
        client: {
          ...checkoutContext.checkoutState.errors.client,
          [name]: "",
        },
      },
    });
  };

  const handleAddressChange = (event, index, attribute) => {
    if (checkoutContext.checkoutState.addressRepeat[index]) {
      return;
    }
    const value = event.target.value;
    const clientEditAddresslist = [...checkoutContext.checkoutState.addresses];
    clientEditAddresslist[index][attribute] = value;
    if (index === 0) {
      if (checkoutContext.checkoutState.addressRepeat[1]) {
        clientEditAddresslist[1][attribute] = value;
      }
      if (checkoutContext.checkoutState.addressRepeat[2]) {
        clientEditAddresslist[2][attribute] = value;
      }
    }

    const searched = [...checkoutContext.checkoutState.searchedAdresses];
    if (attribute === "address1") {
      searched[index] = false;
    }
    checkoutContext.setCheckoutState({
      ...checkoutContext.checkoutState,
      addresses: clientEditAddresslist,
      searchedAdresses: searched,
    });
  };

  const sameAsBusiness = (index) => {
    if (index === 0) {
      return; // failsafe, just in case
    }

    const clientEditAddresslist = [...checkoutContext.checkoutState.addresses];
    const repeatArray = [...checkoutContext.checkoutState.addressRepeat];
    const value = !repeatArray[index];
    repeatArray[index] = value;
    let addressLine1 = "";

    if (value) {
      clientEditAddresslist[index] = {
        ...clientEditAddresslist[index],
        address1: checkoutContext.checkoutState.addresses[0].address1,
        address2: checkoutContext.checkoutState.addresses[0].address2,
        city: checkoutContext.checkoutState.addresses[0].city,
        stateAddress: checkoutContext.checkoutState.addresses[0].stateAddress,
        zip: checkoutContext.checkoutState.addresses[0].zip,
      };
      addressLine1 = checkoutContext.checkoutState.addresses[0].address1;
      checkoutContext.refs[index].current.value = addressLine1;
    } else {
      clientEditAddresslist[index] = {
        ...clientEditAddresslist[index],
        address1: "",
        address2: "",
        city: "",
        stateAddress: "",
        zip: "",
      };
      addressLine1 = "";
    }

    checkoutContext.setCheckoutState({
      ...checkoutContext.checkoutState,
      addressRepeat: repeatArray,
      addresses: clientEditAddresslist,
    });
  };

  const handlePetChange = (event, index) => {
    const value = event.target.value;
    const clientPetslist = [...checkoutContext.checkoutState.pets];
    clientPetslist[index] = value;
    checkoutContext.setCheckoutState({
      ...checkoutContext.checkoutState,
      pets: clientPetslist,
    });
  };

  const handlePetFocus = (event, index) => {
    const clientPetslist = [...checkoutContext.checkoutState.errors.pets];
    clientPetslist[index] = "";
    checkoutContext.setCheckoutState({
      ...checkoutContext.checkoutState,
      errors: {
        ...checkoutContext.checkoutState.errors,
        pets: clientPetslist,
      },
    });
  };

  const handlePhoneChange = (newPhone, info) => {
    let value = newPhone;
    checkoutContext.setCheckoutState({
      ...checkoutContext.checkoutState,
      client: { ...checkoutContext.checkoutState.client, phone: value },
    });
  };

  const handleOnblur = (index) => {
    if (!checkoutContext.checkoutState.searchedAdresses[index]) {
      const allowEditList = [
        ...checkoutContext.checkoutState.allowEditAdresses,
      ];
      allowEditList[index] = {
        ...allowEditList[index],
        zip: true,
        city: true,
        stateAddress: true,
      };
      checkoutContext.setCheckoutState({
        ...checkoutContext.checkoutState,
        allowEditAdresses: allowEditList,
      });
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Clients Information
      </Typography>
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First name"
            value={checkoutContext.checkoutState.client.firstName}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            error={checkoutContext.checkoutState.errors.client.firstName !== ""}
            helperText={
              checkoutContext.checkoutState.errors.client.firstName ??
              "Input Req."
            }
            fullWidth
            autoComplete="given-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            value={checkoutContext.checkoutState.client.lastName}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            error={checkoutContext.checkoutState.errors.client.lastName !== ""}
            helperText={
              checkoutContext.checkoutState.errors.client.lastName ??
              "Input Req."
            }
            fullWidth
            autoComplete="family-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="email"
            name="email"
            label="Email"
            value={checkoutContext.checkoutState.client.email}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            error={checkoutContext.checkoutState.errors.client.email !== ""}
            helperText={
              checkoutContext.checkoutState.errors.client.email ?? "Input Req."
            }
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MuiTelInput
            value={checkoutContext.checkoutState.client.phone}
            onChange={handlePhoneChange}
            onFocus={handleInputFocus}
            fullWidth
            label="Phone"
            id="phone"
            name="phone"
            size="normal"
            variant="standard"
            defaultCountry="US"
            forceCallingCode
            required
            onlyCountries={acceptedPhoneCountries}
            error={checkoutContext.checkoutState.errors.client.phone !== ""}
            helperText={
              checkoutContext.checkoutState.errors.client.phone ?? "Input Req."
            }
          />
        </Grid>
      </Grid>

      {checkoutContext.checkoutState.pets.length > 0 && (
        <Typography variant="h6" gutterBottom>
          Pets Information
        </Typography>
      )}

      <Grid container spacing={3} sx={{ mb: 3 }}>
        {checkoutContext.checkoutState.pets.map((pet, index) => {
          return (
            <Grid key={"Pet #" + (index + 1) + " name"} item xs={12} sm={6}>
              <TextField
                required
                name=""
                label={"Pet #" + (index + 1) + " name"}
                value={checkoutContext.checkoutState.pets[index] ?? ""}
                onChange={(event) => handlePetChange(event, index)}
                onFocus={(event) => handlePetFocus(event, index)}
                fullWidth
                variant="standard"
                error={checkoutContext.checkoutState.errors.pets[index] !== ""}
                helperText={
                  checkoutContext.checkoutState.errors.pets[index] ??
                  "Input Req."
                }
              />
            </Grid>
          );
        })}
      </Grid>

      <AddressFormItem
        item={checkoutContext.checkoutState.addresses[0]}
        index={0}
        stateItem="addresses"
        errors={checkoutContext.checkoutState.errors}
        onChange={handleAddressChange}
        isLoaded={checkoutContext.isLoaded}
        onLoad={checkoutContext.onloads[0]}
        address1Ref={checkoutContext.refs[0]}
        onPlaceChanged={checkoutContext.onPlaceChanged}
        sameAsBusiness={sameAsBusiness}
        repeatValue={checkoutContext.checkoutState.addressRepeat[0]}
        allowEditField={checkoutContext.checkoutState.allowEditAdresses[0]}
        handleOnblur={handleOnblur}
      />

      <AddressFormItem
        item={checkoutContext.checkoutState.addresses[1]}
        index={1}
        stateItem="addresses"
        errors={checkoutContext.checkoutState.errors}
        onChange={handleAddressChange}
        isLoaded={checkoutContext.isLoaded}
        onLoad={checkoutContext.onloads[1]}
        address1Ref={checkoutContext.refs[1]}
        onPlaceChanged={checkoutContext.onPlaceChanged}
        sameAsBusiness={sameAsBusiness}
        repeatValue={checkoutContext.checkoutState.addressRepeat[1]}
        allowEditField={checkoutContext.checkoutState.allowEditAdresses[1]}
        handleOnblur={handleOnblur}
      />

      <AddressFormItem
        item={checkoutContext.checkoutState.addresses[2]}
        index={2}
        stateItem="addresses"
        errors={checkoutContext.checkoutState.errors}
        onChange={handleAddressChange}
        isLoaded={checkoutContext.isLoaded}
        onLoad={checkoutContext.onloads[2]}
        address1Ref={checkoutContext.refs[2]}
        onPlaceChanged={checkoutContext.onPlaceChanged}
        sameAsBusiness={sameAsBusiness}
        repeatValue={checkoutContext.checkoutState.addressRepeat[2]}
        allowEditField={checkoutContext.checkoutState.allowEditAdresses[2]}
        handleOnblur={handleOnblur}
      />
    </React.Fragment>
  );
}
